import { lazy } from 'react';
// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import Users from '../pages/users';
import Dashboards from '../pages/dashboard';
import Dashboard from '../pages/dashboard/Dashboard';
import Connections from '../pages/connections';
import SampleDashboard from '../pages/sample-dashboard';
import Settings from '../pages/settings';
import AdminAccountSettingsDefault from "../pages/admin-account-settings";
import UserAccountSettingsDefault from "../pages/user-account-settings";
import Checkout from "../pages/stripe";

// render - dashboard
const Default = Loadable(lazy(() => import('pages/landing-page')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Default />
        },
        // {
        //     path: 'color',
        //     element: <Color />
        // },
        {
            path: 'user',
            element: <Users />
        },
        {
            path: 'user/:id',
            element: <UserAccountSettingsDefault />
        },
        {
            path: 'bi-dashboards',
            element: <Dashboards />
        },
        {
            path: 'dashboards/:id',
            element: <Dashboard />
        },
        {
            path: 'connections',
            element: <Connections />
        },
        {
            path: 'settings',
            element: <Settings />
        },
        // {
        //     path: 'sample',
        //     element: <SampleDashboard />
        // },
        {
            path: 'account-settings',
            element: <AdminAccountSettingsDefault />
        },
        {
            path: 'checkout',
            element: <Checkout />,
        },
        {
            path: 'checkout?status=success',
            element: <Checkout />,
        },
        {
            path: 'checkout?status=cancelled',
            element: <Checkout />,
        }

    ]
};

export default MainRoutes;
