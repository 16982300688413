import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    useCreateMultipleUserDashboardRelationMutation,
    useDeleteUserDashboardRelationsMutation,
    useGetDashboardDataQuery,
    useGetUsersQuery
} from "../../store/api";

// material-ui
import {
    Box,
    Button,
    FormHelperText,
    Grid,
    InputLabel,
    Modal,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Divider, Link
} from "@mui/material";

// third-party
import NumberFormat from 'react-number-format';

// project import
import Dot from 'components/@extended/Dot';
import AnimateButton from "../../components/@extended/AnimateButton";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import Loader from "../../components/Loader";
import {EditOutlined} from "@ant-design/icons";
import { useTheme } from "@mui/material/styles";

function createData(userid, name, sessions, status, dashboards) {
    return { userid, name, sessions, status, dashboards };
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// ==============================|| ORDER TABLE - HEADER CELL ||============================== //

const headCells = [
    {
        id: 'name',
        align: 'left',
        disablePadding: true,
        label: 'Name'
    },
    {
        id: 'email',
        align: 'left',
        disablePadding: true,
        label: 'Email'
    },
    {
        id: 'sessions',
        align: 'left',
        disablePadding: false,
        label: 'Total Sessions'
    },
    {
        id: 'dashboards',
        align: 'left',
        disablePadding: false,
        label: 'Dashboards'
    },
    {
        id: 'edit',
        align: 'left',
        disablePadding: true,
        label: 'Edit'
    }
];

// ==============================|| ORDER TABLE - HEADER ||============================== //

function UserTableHead({ order, orderBy }) {
    const theme = useTheme();
    return (
        <TableHead style={{backgroundColor: theme.palette.primary.main}}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{color: theme.palette.primary.contrastText}}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

UserTableHead.propTypes = {
    order: PropTypes.string,
    orderBy: PropTypes.string
};

// ==============================|| USER TABLE - STATUS ||============================== //

const Userstatus = ({ status }) => {
    let color;
    let title;

    switch (status) {
        case 0:
            color = 'warning';
            title = 'Disabled';
            break;
        case 1:
            color = 'success';
            title = 'Active';
            break;
        case 2:
            color = 'success';
            title = 'Active';
            break;
        default:
            color = 'primary';
            title = 'None';
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Dot color={color} />
            <Typography>{title}</Typography>
        </Stack>
    );
};

Userstatus.propTypes = {
    status: PropTypes.number
};

// ==============================|| USER TABLE ||============================== //

export default function UserTable() {
    const [order] = useState('asc');
    const [orderBy] = useState('trackingNo');
    const [selected] = useState([]);

    const {data: users, rowerror, isLoading} = useGetUsersQuery();
    const {data: dashboards, dashboarderror} = useGetDashboardDataQuery();

    const [deleteAssociations, {isLoading: deleteAssociationsIsLoading }] = useDeleteUserDashboardRelationsMutation();
    const [addAssociations, { isLoading: addAssociationIsLoading, isError: addAssociationIsError }] = useCreateMultipleUserDashboardRelationMutation();

    const [openDashboardsModal, setOpenDashboardsModal] = useState(false);
    const [openSessionsModal, setOpenSessionsModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const [serverError, setServerError] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [checkedDashboards, setCheckedDashboards] = useState([]);
    const [checkedSessions, setCheckedSessions] = useState([]);
    const navigate = useNavigate();
    const theme = useTheme();
    const handleOpenDashboards = (row) => {
        setCurrentRow(row);
        let tempChecked = [];

        if (row.dashboards.length !== 0){
            for (let i = 0; i < row.dashboards.length; i++) {
                tempChecked.push(row.dashboards[i].association_dashboard_id)
            }
        }
        setCheckedDashboards(tempChecked);

        setOpenDashboardsModal(true);
    };

    const handleCloseDashboards = () => setOpenDashboardsModal(false);

    const handleOpenSessions = (row) => {
        setCurrentRow(row);
        let tempChecked = [];

        if (row.sessions.length !== 0){
            for (let i = 0; i < row.sessions.length; i++) {
                tempChecked.push(row.sessions[i])
            }
        }
        setCheckedSessions(tempChecked);

        setOpenSessionsModal(true);
    };

    const handleCloseSessions = () => setOpenSessionsModal(false);

    const handleOpenEditModal = (row) => {
        setCurrentRow(row);
        let tempChecked = [];

        if (row.sessions.length !== 0){
            for (let i = 0; i < row.sessions.length; i++) {
                tempChecked.push(row.sessions[i])
            }
        }
        setCheckedSessions(tempChecked);

        setOpenEditModal(true);
    };

    const handleEditModalClose = () => setOpenEditModal(false);

    function getDashboardName(dashboard_id){
        for (let dashboard of dashboards){
            if (dashboard.id === dashboard_id){
                return dashboard.name;
            }
        }
    }

    const isSelected = (trackingNo) => selected.indexOf(trackingNo) !== -1;

    if (rowerror) {
        return <h1 align={'center'}>Error</h1>
    }
    if (isLoading || addAssociationIsLoading || deleteAssociationsIsLoading) {
        return <> <Loader /> <h1 align={'center'}>Loading</h1> </>
    }

    return (
        <Box>
            <Modal
                open={openDashboardsModal}
                onClose={handleCloseDashboards}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    pt: 2.25,
                    backgroundColor: "white",
                    width: "90%",
                    margin: "0 auto",
                    marginTop: "100px",
                    padding: "20px",
                    maxHeight: '80vh',  // Set the maximum height of the modal
                    overflowY: 'auto'    // Enable vertical scrolling
                }}>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        Quicksight Dashboards
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Formik
                        initialValues={{
                            checked: checkedDashboards
                        }}
                        validationSchema={Yup.object().shape({
                            checked: Yup.array().min(0)
                        })}
                        onSubmit={async (data) => {
                            try {
                                let oldDashboardIdList = []; // old ids
                                let newDashboardIdList = []; // new ids

                                for (const dashboard of currentRow.dashboards) {
                                    oldDashboardIdList.push(dashboard.association_dashboard_id);
                                }

                                for (const id of data['checked']) {
                                    newDashboardIdList.push(id);
                                }

                                let duplicateFound = true;

                                // remove duplicates
                                while (duplicateFound === true) {
                                    duplicateFound = false;
                                    for (const oldId of oldDashboardIdList) {
                                        if (newDashboardIdList.includes(oldId)) {
                                            duplicateFound = true;
                                            oldDashboardIdList.splice(oldDashboardIdList.indexOf(oldId), 1);
                                            newDashboardIdList.splice(newDashboardIdList.indexOf(oldId), 1);
                                        }
                                    }
                                    for (const newId of newDashboardIdList) {
                                        if (oldDashboardIdList.includes(newId)) {
                                            duplicateFound = true;
                                            newDashboardIdList.splice(newDashboardIdList.indexOf(newId), 1);
                                            oldDashboardIdList.splice(oldDashboardIdList.indexOf(newId), 1);
                                        }
                                    }
                                }

                                console.log("Remove: ", JSON.stringify(oldDashboardIdList));
                                console.log("Add: ", JSON.stringify(newDashboardIdList));

                                const maxRequestLength = 10;
                                let lengthCounter = 0;

                                if (oldDashboardIdList.length !== 0) {
                                    // delete
                                    let idDeleteString = "";
                                    for (const dashboardId of oldDashboardIdList) {
                                        lengthCounter += 1;
                                        idDeleteString = idDeleteString.concat(dashboardId, ">");

                                        if (lengthCounter === maxRequestLength) {
                                            deleteAssociations({userId: currentRow.id, dashboard_ids: idDeleteString});
                                            lengthCounter = 0;
                                            idDeleteString = "";
                                        }
                                    }

                                    if (idDeleteString.includes(">")) {
                                        deleteAssociations({userId: currentRow.id, dashboard_ids: idDeleteString});
                                    }
                                }
                                if (newDashboardIdList.length !== 0) {
                                    // add
                                    let idAddString = "";
                                    lengthCounter = 0;
                                    for (const dashboardId of newDashboardIdList) {
                                        lengthCounter += 1;
                                        idAddString = idAddString.concat(dashboardId, ">");

                                        if (lengthCounter === maxRequestLength) {
                                            addAssociations({userId: currentRow.id, dashboard_ids: idAddString});
                                            lengthCounter = 0;
                                            idAddString = "";
                                        }
                                    }

                                    if (idAddString.includes(">")) {
                                        addAssociations({userId: currentRow.id, dashboard_ids: idAddString});
                                    }
                                }

                            }
                            catch (e) {
                                console.error(e)
                            }
                            handleCloseDashboards();
                            return true;
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    {dashboards && dashboards.map((dashboard, index) => (
                                        <Grid
                                            container
                                            item xs={12}
                                            key={dashboard.id}
                                            sx={{
                                                backgroundColor: index % 2 === 0 ? theme.palette.grey[100] : 'white',
                                                padding: '12px 16px',
                                                borderRadius: '8px',
                                                border: '1px solid',
                                                borderColor: theme.palette.divider,
                                                marginBottom: '8px',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Grid item xs={1}>
                                                <Field
                                                    type="checkbox"
                                                    name="checked"
                                                    value={dashboard.id}
                                                    style={{
                                                        height: '20px',
                                                        width: '20px',
                                                        margin: '0 16px'
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                                    {dashboard.name}
                                                </Typography>
                                                { dashboard.connection &&
                                                    <Typography variant="body2" sx={{ fontWeight: 200 }}>
                                                        Region: {dashboard.connection.region_name}
                                                    </Typography>
                                                }
                                                { dashboard.connection &&
                                                    <Typography variant="body2" sx={{ fontWeight: 200 }}>
                                                        Account ID: {dashboard.connection.aws_account_id}
                                                    </Typography>
                                                }
                                                { dashboard.connection &&
                                                    <Link variant="body2"
                                                          target="_blank"
                                                          rel={"noreferrer"}
                                                          href={"https://" + dashboard.connection.region_name + ".quicksight.aws.amazon.com/sn/dashboards/" + dashboard.id}
                                                          sx={{ fontWeight: 200 }}>
                                                        View
                                                    </Link>
                                                }
                                            </Grid>
                                        </Grid>
                                    ))}
                                    {touched.checked && errors.checked && (
                                        <Grid item xs={12}>
                                            <FormHelperText error id="standard-weight-helper-text-name-user">
                                                {errors.checked}
                                            </FormHelperText>
                                        </Grid>
                                    )}
                                </Grid>
                                {serverError && (
                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <FormHelperText error>Error: {serverError}</FormHelperText>
                                    </Grid>
                                )}
                                <Grid item xs={12} sx={{ mt: 3 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Set Dashboards
                                        </Button>
                                    </AnimateButton>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Modal>

            <Modal
                open={openSessionsModal}
                onClose={handleCloseSessions}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    pt: 2.25,
                    backgroundColor: "white",
                    width: "90%",
                    margin: "0 auto",
                    marginTop: "100px",
                    padding: "20px",
                    maxWidth: "1000px",
                    maxHeight: '80vh',  // Set the maximum height of the modal
                    overflowY: 'auto'    // Enable vertical scrolling
                }}>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        Sessions
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Grid container spacing={2}>
                        {checkedSessions.map((session, index) => (
                            <Grid
                                container
                                item xs={12}
                                key={index}
                                sx={{
                                    backgroundColor: index % 2 === 0 ? theme.palette.grey[100] : 'white',
                                    padding: '12px 16px',
                                    borderRadius: '8px',
                                    border: '1px solid',
                                    borderColor: theme.palette.divider,
                                    marginBottom: '8px',
                                    alignItems: 'center'
                                }}
                            >
                                <Grid item xs={5}>
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                        {getDashboardName(session.dashboard_id)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                                        {session.created_at.substring(0, 10)} {session.created_at.substring(11, 16)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openEditModal}
                onClose={handleEditModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ pt: 2.25, backgroundColor: "white", width: "90%", margin: "0 auto",
                    marginTop: "100px", padding: "20px", maxWidth: "1000px"
                }}>
                    <span> Edit User </span>
                    <hr/>
                    <Grid>
                        {checkedSessions.map(session =>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Stack spacing={1}>
                                        <p style={{marginTop: '3px'}}>
                                            {getDashboardName(session.dashboard_id)}
                                        </p>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack spacing={1}>
                                        <p style={{marginTop: '3px'}}>
                                            {session.created_at.substring(0,10)} {session.created_at.substring(11,16)}
                                        </p>
                                    </Stack>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Modal>
            { addAssociationIsError &&
                <Box sx={{p: 2, backgroundColor: theme.palette.error.lighter}}>
                    <Typography color="error" align="center" variant="h6">
                        An error occurred adding permissions to your dashboards. Check that the IAM role still has the relevant permissions to manage Quicksight permissions.
                    </Typography>
                </Box>
            }

            <TableContainer
                sx={{
                    width: '100%',
                    overflowX: 'auto',
                    position: 'relative',
                    display: 'block',
                    maxWidth: '100%',
                    '& td, & th': { whiteSpace: 'nowrap' }
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    sx={{
                        '& .MuiTableCell-root:first-child': {
                            pl: 2
                        },
                        '& .MuiTableCell-root:last-child': {
                            pr: 3
                        }
                    }}
                >
                    <UserTableHead order={order} orderBy={orderBy} />
                    <TableBody>
                        { users && stableSort(users, getComparator(order, orderBy)).map((user, index) => {
                            const isItemSelected = isSelected(user.trackingNo);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={user.id}
                                    selected={isItemSelected}
                                >
                                    <TableCell align="left">{user.name}</TableCell>
                                    <TableCell align="left">{user.email}</TableCell>
                                    <TableCell align="left">
                                        <Grid container alignItems="right" justifyContent="space-between">
                                        <Grid item>
                                            <AnimateButton>
                                                <Button
                                                    onClick={() => handleOpenSessions(user)}
                                                >
                                                    <NumberFormat value={user.sessions.length} displayType="text" thousandSeparator />
                                                </Button>
                                            </AnimateButton>
                                        </Grid>
                                        <Grid item />
                                    </Grid>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Grid container alignItems="right" justifyContent="space-between">
                                            <Grid item>
                                                <AnimateButton>
                                                    <Button
                                                        onClick={() => handleOpenDashboards(user)}
                                                    >
                                                        <NumberFormat value={user.dashboards.length} displayType="text" thousandSeparator />
                                                    </Button>
                                                </AnimateButton>
                                            </Grid>
                                            <Grid item />
                                        </Grid>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Grid container alignItems="center" justifyContent="space-between">
                                            <Grid item>
                                                    <Button
                                                        onClick={() => navigate(`${user.id}`)}
                                                        style={{minWidth:"10px"}}
                                                    >
                                                        <EditOutlined />
                                                    </Button>
                                            </Grid>
                                            <Grid item />
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            );
                        }) }
                    </TableBody>
                    {users && users.length === 0 && (
                        <TableBody>
                            <TableRow>
                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                    <Typography variant="subtitle1" component="div">
                                        No Users Found
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
        </Box>
    );
}
