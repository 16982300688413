import PropTypes from 'prop-types';
import { Link, useNavigate } from "react-router-dom";

// material-ui
import { ButtonBase } from '@mui/material';

// project import
import {LogoBlue, Logo} from './Logo';
import config from 'config';
import { useDispatch, useSelector } from "react-redux";
import { activeItem } from "../../store/reducers/menu";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ sx, to }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector((state) => state.auth);

    const handleClick = () => {
        if (!isLoggedIn) {
            navigate('/login');
            return;
        }

        dispatch(activeItem({ openItem: ["home1"] }));
        navigate('/');
    };

    return(
        <ButtonBase disableRipple onClick={handleClick} sx={sx}>
            <LogoBlue />
        </ButtonBase>
    );
};

export const LogoSectionBlue = ({ sx, to }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector((state) => state.auth);

    const handleClick = () => {
        if (!isLoggedIn) {
            navigate('/login');
            return;
        }

        dispatch(activeItem({ openItem: ["home1"] }));
        navigate('/');
    };

    return (
        <ButtonBase disableRipple onClick={handleClick} sx={sx}>
            <Logo />
        </ButtonBase>
    );
};

LogoSection.propTypes = {
    sx: PropTypes.object,
    to: PropTypes.string
};

export default LogoSection;
