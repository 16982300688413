import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setMessage } from './message';

import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));

export const register = createAsyncThunk('auth/register', async ({ name, email, password, passwordConfirm, company }, thunkAPI) => {
    try {
        const response = await AuthService.register(name, email, password, passwordConfirm, company);
        thunkAPI.dispatch(setMessage(response.data.message));
        return response.data;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue(message);
    }
});

export const login = createAsyncThunk('auth/login', async ({ email, password }, thunkAPI) => {
    try {
        const data = await AuthService.login(email, password);
        return { user: data };
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue(message);
    }
});

export const logout = createAsyncThunk('auth/logout', async () => {
    await AuthService.logout();
});

export const forgot = createAsyncThunk('auth/login', async ({ email }, thunkAPI) => {
    try {
        const data = await AuthService.forgot(email);
        return { user: data };
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue(message);
    }
});

export const token = createAsyncThunk('auth/logout', async () => {
    //debugger
});

const initialState = user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: null };

const authSlice = createSlice({
    name: 'auth',
    initialState,
    extraReducers: {
        [register.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
        },
        [register.rejected]: (state, action) => {
            state.isLoggedIn = false;
        },
        [login.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [login.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        [logout.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        [token.fulfilled]: (state, action) => {
            state.token = action.payload.token;
            state.isLoggedIn = true;
        }
    }
});

const { reducer } = authSlice;
export default reducer;
