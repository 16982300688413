// material-ui
import {
    Box,
    Button,
    FormHelperText,
    Grid,
    InputLabel,
    List,
    ListItem,
    Modal,
    OutlinedInput,
    Stack,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@mui/material";
// project import
import ConnectionsTable from "./ConnectionsTable";
import MainCard from "components/MainCard";
import AnimateButton from "../../components/@extended/AnimateButton";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import {
    useAddQuicksightConnectionMutation,
    useGetQuicksightConnectionsQuery, useUpdateConnectionValidateStatusMutation, useUpdateQuicksightConnectionMutation,
    useUpdateQuicksightConnectionRefreshMutation,
    useUpdateQuicksightDashboardRefreshMutation
} from "../../store/api";
import { ROLE_URL } from "config";

// ==============================|| USERS - DEFAULT ||============================== //

const ConnectionsDefault = () => {
    const [open, setOpen] = useState(false);
    const [openManualConnection, setOpenManualConnection] = useState(false);
    const [serverError, setServerError] = useState(null);
    const [iamPolicy, setIamPolicy] = useState(null);
    const [trustPolicy, setTrustPolicy] = useState(null);
    const [externalId, setExternalId] = useState("");
    const [connectionDetails, setConnectionDetails] = useState(null);

    const [addConnection, { isLoading, isError: isAddConnectionError, data: data}] = useAddQuicksightConnectionMutation();
    const [updateConnection, { isUpdating: isUpdateConnectionLoading, isError: isUpdateConnectionError }] = useUpdateQuicksightConnectionMutation();
    const [refreshQuicksightDashboards, { isLoading: isRefreshUpdating, isError: isRefreshError }] = useUpdateQuicksightDashboardRefreshMutation();
    const [refreshQuicksightConnections, { isRefreshLoading: isRefreshConnectionUpdating }] = useUpdateQuicksightConnectionRefreshMutation();
    const [getConnectionValidation, { isRefreshLoading: isConnectionValidating }] = useUpdateConnectionValidateStatusMutation();

    const [activeStep, setActiveStep] = React.useState(0);
    const [activeStepManualConnection, setActiveStepManualConnection] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [tabValue, setTabValue] = React.useState("1");
    const [connectionIsValid, setConnectionIsValid] = React.useState(false);
    const [connectionIsInvalid, setConnectionIsInvalid] = React.useState(false);

    const {data: rows, isLoading: isConnectionsLoading, error: isConnectionsError} = useGetQuicksightConnectionsQuery();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const isStepOptional = (step) => {
        return step === 6;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        if (steps.length === activeStep + 1) {
            setOpen(false);
            refreshQuicksightConnections();
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNextManualConnection = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStepManualConnection)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStepManualConnection);
        }

        setActiveStepManualConnection((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBackManualConnection = () => {
        setActiveStepManualConnection((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleOpen = () => {
        setConnectionDetails(null);
        setOpen(true);
    };
    const handleClose = () => {
        setActiveStep(0);
        setOpen(false);
        setConnectionIsInvalid(false);
        setConnectionIsValid(false);
        setServerError(null);

    };
    const handleReset = () => {
        setActiveStep(0);
    };


    const handleOpenManualConnection = () => {
        setConnectionDetails(null);
        setOpenManualConnection(true);
    };

    const handleCloseManualConnection = () => {
        setActiveStep(0);
        setOpenManualConnection(false);
        setServerError(null);
    };
    const handleResetManualConnection = () => {
        setActiveStepManualConnection(0);
        setOpenManualConnection(false);
        refreshQuicksightConnections();
    };

    const steps = ["Overview", "Add Connection", "Cloudformation", "Test Connection"];
    const stepsManual = ["Overview", "Add Connection", "IAM Policy", "IAM Role", "Test Connection"];

    function launchCloudformation() {
        window.open(`https://${connectionDetails.region_name}.console.aws.amazon.com/cloudformation/home?#/stacks/create/review?stackName=CakeBi-${connectionDetails.region_name}&templateURL=${ROLE_URL}&param_ExternalId=${connectionDetails.external_id}&param_CompanyId=${connectionDetails.company_id}&param_ConnectionId=${connectionDetails.id}`);
        var pollTimer = window.setInterval(function() {
            if (window.closed !== false) { // !== is required for compatibility with Opera
                window.clearInterval(pollTimer);
                console.log("Window Closed");
                setOpen(false);
                refreshQuicksightDashboards(connectionDetails.id);
            }
        }, 2000);
    }

    async function checkConnectionIsValid(connectionId) {
        var refreshStatus = await refreshQuicksightDashboards(connectionId);
        if (refreshStatus.data && refreshStatus.data.status === "success" || refreshStatus.data.detail === "Connection already validated") {
            setConnectionIsValid(true);
            setConnectionIsInvalid(false);
        } else {
            setConnectionIsValid(false);
            setConnectionIsInvalid(true);
        }
    }

    const regions = [

        { name: "Select Region", id: "ap-south-1" },
        { name: "Asia Pacific (Mumbai), ap-south-1", id: "ap-south-1" },
        // {name: 'Asia Pacific (Osaka), ap-northeast-3', id: 'ap-northeast-3'},
        { name: "Asia Pacific (Seoul), ap-northeast-2", id: "ap-northeast-2" },
        { name: "Asia Pacific (Singapore), ap-southeast-1", id: "ap-southeast-1" },
        { name: "Asia Pacific (Sydney), ap-southeast-2", id: "ap-southeast-2" },
        { name: "Asia Pacific (Tokyo), ap-northeast-1", id: "ap-northeast-1" },

        { name: "Canada (Central), ca-central-1", id: "ca-central-1" },

        { name: "Europe (Frankfurt), eu-central-1", id: "eu-central-1" },
        { name: "Europe (Ireland), eu-west-1", id: "eu-west-1" },
        { name: "Europe (London), eu-west-2", id: "eu-west-2" },
        // {name: 'Europe (Milan), eu-south-1', id: 'eu-south-1'},
        // {name: 'Europe (Paris), eu-west-3', id: 'eu-west-3'},
        // {name: 'Europe (Spain), eu-south-2', id: 'eu-south-2'},
        { name: "Europe (Stockholm), eu-north-1", id: "eu-north-1" },
        // {name: 'Europe (Zurich), eu-central-2', id: 'eu-central-2'},

        // {name: 'Israel (Tel Aviv), il-central-1', id: 'il-central-1'},

        // {name: 'Middle East (Baharian), me-south-1', id: 'me-south-1'},
        // {name: 'Middle East (UAE), me-central-1', id: 'me-central-1'},

        { name: "South America (Sao Paulo), sa-east-1", id: "sa-east-1" },

        { name: "US East (N. Virginia), us-east-1", id: "us-east-1" },
        { name: "US East (Ohio), us-east-2", id: "us-east-2" },
        // {name: 'US West (N. California), us-west-1', id: 'us-west-1'},
        { name: "US West (Oregon), us-west-2", id: "us-west-2" }

    ];
    useEffect(() => {
        setIamPolicy(`{
    "Version": "2012-10-17",
    "Statement": [{
        "Sid": "CakeBIPolicy",
        "Effect": "Allow",
        "Action": ["quicksight:DescribeUser", "quicksight:RegisterUser", 
            "quicksight:GenerateEmbedUrlForRegisteredUser"],
        "Resource": "arn:aws:quicksight:*:*:user/default/CakeBI-*"
    },
    {
        "Sid": "CakeBIPolicy2",
        "Effect": "Allow",
        "Action": ["quicksight:UpdateDashboardPermissions", "quicksight:ListDashboards"],
        "Resource": "arn:aws:quicksight:${connectionDetails ? connectionDetails.region_name : 'Region Name'}:*:dashboard/*"
    }]
}
`
        );
        setTrustPolicy(`{
    "Version": "2012-10-17",
    "Statement": [{
        "Sid": "Statement1",
        "Effect": "Allow",
        "Principal": {
            "AWS": ["arn:aws:iam::527025842421:role/CakeBI-1s95"]
        },
        "Action": "sts:AssumeRole",
        "Condition": {
            "StringEquals": { "sts:ExternalId": "${externalId}" }
        }
    }]
}
`
        );
    });
    // Using a query hook automatically fetches data and returns query values
    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            {/* row 3 */}
            <Grid item>
                <div>
                    <span style={{ float: "left", paddingRight: "10px" }}>
                        <AnimateButton>
                            <Button
                                disableElevation
                                disabled={false}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleOpen}
                            >
                                Add Connection Using Cloudformation
                            </Button>
                        </AnimateButton>
                    </span>
                    <span style={{ float: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                        <AnimateButton>
                            <Button
                                disableElevation
                                disabled={false}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleOpenManualConnection}
                            >
                                Add Connection Using IAM
                            </Button>
                        </AnimateButton>
                    </span>
                    <span style={{ float: "right", paddingLeft: "10px" }}>
                        <AnimateButton>
                            {!isRefreshConnectionUpdating ? (
                                <Button
                                    disableElevation
                                    disabled={false}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                    onClick={refreshQuicksightConnections}
                                >
                                    Refresh Connections
                                </Button>
                            ) : (
                                <Loader />
                            )}
                        </AnimateButton>
                    </span>
                </div>
                <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
                       aria-describedby="modal-modal-description">

                    <Box
                        sx={{
                            pt: 2.25,
                            backgroundColor: "white",
                            width: "90%",
                            margin: "0 auto",
                            marginTop: "100px",
                            padding: "20px",
                            maxWidth: "800px"
                        }}
                    >
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                if (isStepOptional(index)) {
                                    labelProps.optional = (
                                        <Typography variant="caption">Optional</Typography>
                                    );
                                }
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>


                        {activeStep === 0 && (
                            <Typography sx={{ mt: 2, mb: 1 }}>
                                <Typography variant="subtitle" noWrap>Connect Cake BI to your AWS
                                    account</Typography><br />
                                To embed your Quicksight reports in your new portal we require a few IAM
                                permissions.<br />
                                We've made a cloudformation template to make this easy.<br />
                                The cloudformation template will add a policy that allows CakeBI to<br />
                                <Box sx={{ width: "100%", maxWidth: 500, marginLeft: 5, fontSize: "12px" }}>
                                    <List sx={{ listStyleType: "disc" }}>
                                        <ListItem sx={{ display: "list-item" }}><Typography variant="subtitle1" noWrap>Obtain
                                            a signed URL to a dashboard</Typography></ListItem>
                                        <ListItem sx={{ display: "list-item" }}><Typography variant="subtitle1" noWrap>List
                                            your dashboards</Typography></ListItem>
                                        <ListItem sx={{ display: "list-item" }}><Typography variant="subtitle1" noWrap>Add
                                            Quicksight Users (with CakeBI- as the prefix)</Typography></ListItem>
                                    </List>
                                </Box>

                                Each connection you make links one region in one AWS account to Cake BI. <br /><br />
                                To share dashboards from different regions or different accounts, create<br />
                                multiple connections.<br /><br />
                                To proceed click Next.

                            </Typography>
                        )}
                        {activeStep === 1 &&
                            <Formik
                                initialValues={{
                                    name: "",
                                    description: "",
                                    region_name: ""
                                }}
                                validationSchema={Yup.object().shape({
                                    name: Yup.string().min(4).max(255).required("Name is required"),
                                    description: Yup.string().max(255).required("Description is required"),
                                    region_name: Yup.string().max(255).required("Region is required")
                                })}
                                onSubmit={async (data) => {
                                    console.log(data);

                                    try {
                                        let newConnection = await addConnection(data).unwrap();
                                        setConnectionDetails(newConnection);
                                        setExternalId(newConnection.external_id);
                                        setActiveStepManualConnection(5);
                                    } catch (e) {
                                        setServerError(e.data.detail);
                                    }
                                    return true;
                                }}
                            >
                                {({
                                      errors,
                                      handleBlur,
                                      handleChange,
                                      handleSubmit,
                                      isSubmitting,
                                      touched,
                                      values
                                  }) => (
                                    <form noValidate onSubmit={handleSubmit}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} style={{ paddingTop: 60 }}>
                                                <Stack spacing={1}>
                                                    <InputLabel htmlFor="name-connection">Name</InputLabel>
                                                    <OutlinedInput
                                                        id="name-connection"
                                                        type="name"
                                                        value={values.name}
                                                        name="name"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        disabled={connectionDetails}
                                                        placeholder="Name"
                                                        fullWidth
                                                        error={Boolean(touched.name && errors.name)}
                                                    />
                                                    {touched.name && errors.name && (
                                                        <FormHelperText error
                                                                        id="standard-weight-helper-text-name-dashboard">
                                                            {errors.name}
                                                        </FormHelperText>
                                                    )}
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack spacing={1}>
                                                    <InputLabel
                                                        htmlFor="description-connection">Description</InputLabel>
                                                    <OutlinedInput
                                                        id="description-connection"
                                                        type="description"
                                                        value={values.description}
                                                        name="description"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        disabled={connectionDetails}
                                                        placeholder="Description"
                                                        fullWidth
                                                        error={Boolean(touched.description && errors.description)}
                                                    />
                                                    {touched.description && errors.description && (
                                                        <FormHelperText error
                                                                        id="standard-weight-helper-text-account-id-connection">
                                                            {errors.description}
                                                        </FormHelperText>
                                                    )}
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack spacing={1}>
                                                    <InputLabel htmlFor="iam-role-connection">Region</InputLabel>
                                                    <Field
                                                        component={"select"}
                                                        style={{
                                                            height: "40px",
                                                            paddingLeft: "10px",
                                                            fontFamily: "Public Sans, sans-serif",
                                                            backgroundColor: "#ffffff",
                                                            border: "1.5px solid #d9d9d9",
                                                            borderRadius: "5px"
                                                        }}

                                                        id="region_name"
                                                        type="region_name"
                                                        value={values.region}
                                                        name="region_name"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        disabled={connectionDetails}

                                                    >
                                                        {regions && regions.map(region =>
                                                            <option value={region.id}
                                                                    style={{ fontFamily: "Public Sans, sans-serif" }}>
                                                                {region.name}
                                                            </option>
                                                        )}

                                                    </Field>

                                                </Stack>
                                            </Grid>
                                            {errors.submit && (
                                                <Grid item xs={12}>
                                                    <FormHelperText error>Errors - {errors.submit}</FormHelperText>
                                                </Grid>
                                            )}
                                            {serverError && serverError.length > 0 && (
                                                <Grid item xs={12}>
                                                    <FormHelperText error>Error: {serverError}</FormHelperText>
                                                </Grid>
                                            )}
                                            {!connectionDetails ? (
                                                <Grid item xs={12}>
                                                    <AnimateButton>
                                                        {!isLoading ? (
                                                            <Button
                                                                disableElevation
                                                                disabled={isSubmitting || connectionDetails}
                                                                fullWidth
                                                                size="large"
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                            >
                                                                Create Connection
                                                            </Button>
                                                        ) : (
                                                            <Loader />
                                                        )}
                                                        {isAddConnectionError && isAddConnectionError.length > 0 &&
                                                            <FormHelperText
                                                                error>Error: {isAddConnectionError}</FormHelperText>
                                                        }
                                                    </AnimateButton>
                                                </Grid>
                                            ) : <Grid item xs={12}><Typography sx={{ width: 1 / 2, mt: 2, mb: 1 }}>

                                                Perfect! Click next to connect your AWS account to this connection.
                                            </Typography></Grid>}
                                        </Grid>
                                    </form>
                                )}
                            </Formik>}
                        {activeStep == 2 && (

                            <Grid item xs={12} style={{ paddingTop: 20, paddingBottom: 20 }}>

                                <Typography sx={{ mt: 2, mb: 1, pb: 5 }}>
                                    <h4>Run a cloudformation script to create the IAM role.</h4>
                                    This is the easiest way to add the IAM permissions.<br />
                                    Click the button below to run the cloudformation script in your preferred region
                                    and AWS account.<br />
                                    Once the script has completed, press Refresh Connections to load your
                                    dashboards.<br />
                                </Typography>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        backgroundColor="#4449DE"
                                        onClick={launchCloudformation}
                                    >
                                        Run Cloudformation Script
                                    </Button>
                                </AnimateButton>
                            </Grid>

                        )}
                        {activeStep == 3 && (
                                <Grid item xs={12} style={{ paddingTop: 20 }}>
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                        Once your cloudformation template has completed, press the Test Connection button to check everything is working correctly.<br />
                                    </Typography>
                                    <Typography sx={{ mt: 2, mb: 1 }}>

                                        <div style={{paddingTop: 10}}></div>
                                        <AnimateButton>
                                            <Button
                                                disableElevation
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => {
                                                    checkConnectionIsValid(connectionDetails.id);
                                                }}
                                            >
                                                Test Connection
                                            </Button>
                                        </AnimateButton>
                                        { connectionIsValid && <Typography sx={{ mt:2, mb: 1 }}>Connection is valid. You can now close this window.</Typography>}
                                        { connectionIsInvalid && <Typography sx={{ mt:2, mb: 1 }}>Connection is invalid. Check that the cloudformation script ran successfully.</Typography>}
                                    </Typography>
                                </Grid>
                            )}
                        {activeStep === 4 ? (
                            <React.Fragment>

                                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                                    <Box sx={{ flex: "1 1 auto" }} />
                                    <Button onClick={handleClose}>Close</Button>
                                </Box>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                                    <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                    <Box sx={{ flex: "1 1 auto" }} />
                                    <Button onClick={handleNext}
                                            disabled={activeStep === 1 && !connectionDetails ? "Next" : null}>
                                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                                    </Button>
                                </Box>
                            </React.Fragment>
                        )}
                    </Box>
                </Modal>
                { (isRefreshUpdating || isRefreshConnectionUpdating) && <Loader /> }

                <Modal open={openManualConnection} onClose={handleCloseManualConnection}
                       aria-labelledby="modal-modal-title"
                       aria-describedby="modal-modal-description">
                    <Box
                        sx={{
                            pt: 2.25,
                            backgroundColor: "white",
                            width: "90%",
                            margin: "0 auto",
                            marginTop: "100px",
                            padding: "20px",
                            maxWidth: "800px"
                        }}
                    >

                        <Stepper activeStep={activeStepManualConnection}>
                            {stepsManual.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>


                        {activeStepManualConnection === 0 && (


                            <Typography sx={{ mt: 2, mb: 1 }}>
                                <Typography variant="subtitle" noWrap>Connect Cake BI to your AWS
                                    account</Typography><br />
                                To embed your Quicksight reports in your new portal we require a few IAM
                                permissions.<br />
                                The IAM policy will allow CakeBI to<br />
                                <Box sx={{ width: "100%", maxWidth: 500, marginLeft: 5, fontSize: "12px" }}>
                                    <List sx={{ listStyleType: "disc" }}>
                                        <ListItem sx={{ display: "list-item" }}><Typography variant="subtitle1" noWrap>Obtain
                                            a signed URL to a dashboard</Typography></ListItem>
                                        <ListItem sx={{ display: "list-item" }}><Typography variant="subtitle1" noWrap>List
                                            your dashboards</Typography></ListItem>
                                        <ListItem sx={{ display: "list-item" }}><Typography variant="subtitle1" noWrap>Add
                                            Quicksight Users (with CakeBI- as the prefix)</Typography></ListItem>
                                    </List>
                                </Box>

                                Each connection you make links one region in one AWS account to Cake BI. <br /><br />
                                To share dashboards from different regions or different accounts, create<br />
                                multiple connections.<br /><br />
                                To proceed click Next.

                            </Typography>
                        )}
                        {activeStepManualConnection === 1 &&
                            <Formik
                                initialValues={{
                                    name: "",
                                    description: "",
                                    region_name: ""
                                }}
                                validationSchema={Yup.object().shape({
                                    name: Yup.string().min(4).max(255).required("Name is required"),
                                    description: Yup.string().max(255).required("Description is required"),
                                    region_name: Yup.string().max(255).required("Region is required")
                                })}
                                onSubmit={async (data) => {
                                    console.log(data);

                                    try {
                                        let newConnection = await addConnection(data).unwrap();
                                        setConnectionDetails(newConnection);
                                        setExternalId(newConnection.external_id);
                                    } catch (e) {
                                        setServerError(e.data.detail);
                                    }
                                    return true;
                                }}
                            >
                                {({
                                      errors,
                                      handleBlur,
                                      handleChange,
                                      handleSubmit,
                                      isSubmitting,
                                      touched,
                                      values
                                  }) => (
                                    <form noValidate onSubmit={handleSubmit}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} style={{ paddingTop: 60 }}>
                                                <Stack spacing={1}>
                                                    <InputLabel htmlFor="name-connection">Name</InputLabel>
                                                    <OutlinedInput
                                                        id="name-connection"
                                                        type="name"
                                                        value={values.name}
                                                        name="name"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        disabled={connectionDetails}
                                                        placeholder="Name"
                                                        fullWidth
                                                        error={Boolean(touched.name && errors.name)}
                                                    />
                                                    {touched.name && errors.name && (
                                                        <FormHelperText error
                                                                        id="standard-weight-helper-text-name-dashboard">
                                                            {errors.name}
                                                        </FormHelperText>
                                                    )}
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack spacing={1}>
                                                    <InputLabel
                                                        htmlFor="description-connection">Description</InputLabel>
                                                    <OutlinedInput
                                                        id="description-connection"
                                                        type="description"
                                                        value={values.description}
                                                        name="description"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        disabled={connectionDetails}
                                                        placeholder="Description"
                                                        fullWidth
                                                        error={Boolean(touched.description && errors.description)}
                                                    />
                                                    {touched.description && errors.description && (
                                                        <FormHelperText error
                                                                        id="standard-weight-helper-text-account-id-connection">
                                                            {errors.description}
                                                        </FormHelperText>
                                                    )}
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack spacing={1}>
                                                    <InputLabel htmlFor="iam-role-connection">Region</InputLabel>
                                                    <Field
                                                        component={"select"}
                                                        style={{
                                                            height: "40px",
                                                            paddingLeft: "10px",
                                                            fontFamily: "Public Sans, sans-serif",
                                                            backgroundColor: "#ffffff",
                                                            border: "1.5px solid #d9d9d9",
                                                            borderRadius: "5px"
                                                        }}

                                                        id="region_name"
                                                        type="region_name"
                                                        value={values.region}
                                                        name="region_name"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        disabled={connectionDetails}

                                                    >
                                                        {regions && regions.map(region =>
                                                            <option value={region.id}
                                                                    style={{ fontFamily: "Public Sans, sans-serif" }}>
                                                                {region.name}
                                                            </option>
                                                        )}

                                                    </Field>

                                                </Stack>
                                            </Grid>
                                            {errors.submit && (
                                                <Grid item xs={12}>
                                                    <FormHelperText error>Errors - {errors.submit}</FormHelperText>
                                                </Grid>
                                            )}
                                            {serverError && serverError.length > 0 && (
                                                <Grid item xs={12}>
                                                    <FormHelperText error>Error: {serverError}</FormHelperText>
                                                </Grid>
                                            )}
                                            {!connectionDetails ? (
                                                <Grid item xs={12}>
                                                    <AnimateButton>
                                                        {!isLoading ? (
                                                            <Button
                                                                disableElevation
                                                                disabled={isSubmitting || connectionDetails}
                                                                fullWidth
                                                                size="large"
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                            >
                                                                Create Connection
                                                            </Button>
                                                        ) : (
                                                            <Loader />
                                                        )}
                                                        {isAddConnectionError && isAddConnectionError.length > 0 &&
                                                            <FormHelperText
                                                                error>Error: {isAddConnectionError}</FormHelperText>
                                                        }
                                                    </AnimateButton>
                                                </Grid>
                                            ) : <Grid item xs={12}><Typography sx={{ width: 1 / 2, mt: 2, mb: 1 }}>

                                                Perfect! Click next to connect your AWS account to this connection.
                                            </Typography>
                                            </Grid>
                                            }
                                        </Grid>
                                    </form>
                                )}
                            </Formik>
                        }


                        {activeStepManualConnection === 2 && (

                            <Grid item xs={12} style={{ paddingTop: 10 }}>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    Add a new IAM policy in your AWS account. Click the link below and paste the IAM policy into the JSON policy editor.<br/>
                                    Save the policy with the name CakeBIPolicy{connectionDetails && '-' + connectionDetails.region_name}.<br/>
                                    [ <a target="_blank" href={'https://us-east-1.console.aws.amazon.com/iam/home?#/policies/create'}>https://us-east-1.console.aws.amazon.com/iam/home?#/policies/create</a> ]
                                    <pre style={{ height: '100%', border: '1px solid black' }}>
                                        <code style={{ fontSize: "0.7rem" }}>
                                            {iamPolicy}
                                        </code>
                                    </pre>
                                </Typography>
                            </Grid>
                            )}
                        {activeStepManualConnection === 3 && (
                            <Grid item xs={12} style={{ paddingTop: 20 }}>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    Add a new IAM role in your AWS account. Click the link and add a new Custom Trust Policy using the JSON below.<br/>
                                    [ <a target="_blank" href={'https://us-east-1.console.aws.amazon.com/iam/home?#/roles/create'}>https://us-east-1.console.aws.amazon.com/iam/home?#/roles/create</a> ]
                                    <br />
                                    <pre style={{  height: '100%', border: '1px solid black' }}>
                                        <code style={{ fontSize: "0.7rem" }}>
                                            {trustPolicy}
                                        </code>
                                    </pre>
                                    Attach your policy from the previous step.<br />
                                    Important: We need to use a unique External ID included in this role. This External ID is unique to this connection.<br/>
                                    Click Next, and give your role a name. You will need the role name in the next step. Press Save.<br/>
                                    Click Create Role.

                                </Typography>

                            </Grid>
                        )}
                        {activeStepManualConnection === 4 && (
                            <Grid item xs={12} style={{ paddingTop: 20 }}>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    Enter your IAM Role and AWS Account ID<br />
                                </Typography>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    <Formik
                                      initialValues={{
                                          iam_role: "",
                                          aws_account_id: ""
                                      }}
                                      validationSchema={Yup.object().shape({
                                          iam_role: Yup.string().max(255).required("IAM Role is required"),
                                          aws_account_id: Yup.number().required("AWS Account ID is required")
                                      })}
                                      onSubmit={async (data) => {
                                          console.log(data);

                                          try {
                                              let updatedConnection = await updateConnection({
                                                  name: connectionDetails.name,
                                                  description: connectionDetails.description,
                                                  region_name: connectionDetails.region_name,
                                                  id: connectionDetails.id,
                                                  aws_account_id: data.aws_account_id,
                                                  iam_role: data.iam_role,
                                                  validated: connectionDetails.validated,
                                                  company_id: connectionDetails.company_id,
                                                  external_id: connectionDetails.external_id,
                                              }).unwrap();
                                              setConnectionDetails(updatedConnection);
                                              getConnectionValidation(updatedConnection);
                                              refreshQuicksightDashboards(updatedConnection.id);
                                              setActiveStepManualConnection(5);
                                          } catch (e) {
                                              setServerError(e.data.detail);
                                          }
                                          return true;
                                      }}
                                    >
                                        {({
                                              errors,
                                              handleBlur,
                                              handleChange,
                                              handleSubmit,
                                              isUpdating,
                                              touched,
                                              values
                                          }) => (
                                          <form noValidate onSubmit={handleSubmit}>
                                              <Grid container spacing={3}>
                                                  <Grid item xs={12}>
                                                      <Stack spacing={1}>
                                                          <InputLabel
                                                            htmlFor="iam_role">IAM Role</InputLabel>
                                                          <OutlinedInput
                                                            id="iam_role"
                                                            type="iam_role"
                                                            value={values.iam_role}
                                                            name="iam_role"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            placeholder="IAM Role"
                                                            fullWidth
                                                            error={Boolean(touched.iam_role && errors.iam_role)}
                                                          />
                                                          {touched.iam_role && errors.iam_role && (
                                                            <FormHelperText error
                                                                            id="standard-weight-helper-text-account-id-connection">
                                                                {errors.iam_role}
                                                            </FormHelperText>
                                                          )}
                                                      </Stack>
                                                  </Grid>
                                                  <Grid item xs={12}>
                                                      <Stack spacing={1}>
                                                          <InputLabel
                                                            htmlFor="aws_account_id">AWS Account ID</InputLabel>
                                                          <OutlinedInput
                                                            id="aws_account_id"
                                                            type="aws_account_id"
                                                            value={values.aws_account_id}
                                                            name="aws_account_id"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            placeholder="AWS Account ID"
                                                            fullWidth
                                                            error={Boolean(touched.aws_account_id && errors.aws_account_id)}
                                                          />
                                                          {touched.aws_account_id && errors.aws_account_id && (
                                                            <FormHelperText error
                                                                            id="standard-weight-helper-text-account-id-connection">
                                                                {errors.aws_account_id}
                                                            </FormHelperText>
                                                          )}
                                                      </Stack>
                                                  </Grid>
                                                  <Grid item xs={12}>
                                                      <Stack spacing={1}>
                                                          <InputLabel
                                                            htmlFor="external_id">External ID</InputLabel>
                                                          <OutlinedInput
                                                            id="external_id"
                                                            type="external_id"
                                                            value={externalId}
                                                            name="externalId"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            placeholder="External ID"
                                                            fullWidth
                                                            disabled
                                                            error={Boolean(touched.external_id && errors.external_id)}
                                                          />
                                                          {touched.external_id && errors.external_id && (
                                                            <FormHelperText error
                                                                            id="standard-weight-helper-text-account-id-connection">
                                                                {errors.external_id}
                                                            </FormHelperText>
                                                          )}
                                                      </Stack>
                                                  </Grid>
                                                  {errors.submit && (
                                                    <Grid item xs={12}>
                                                        <FormHelperText error>Errors - {errors.submit}</FormHelperText>
                                                    </Grid>
                                                  )}
                                                  {serverError && serverError.length > 0 && (
                                                    <Grid item xs={12}>
                                                        <FormHelperText error>Error: {serverError}</FormHelperText>
                                                    </Grid>
                                                  )}
                                                  {connectionDetails ? (
                                                    <Grid item xs={12}>
                                                        <AnimateButton>
                                                            {!isLoading ? (
                                                              <Button
                                                                disableElevation
                                                                disabled={isUpdating}
                                                                fullWidth
                                                                size="large"
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                              >
                                                                  Save Role and Account
                                                              </Button>
                                                            ) : (
                                                              <Loader />
                                                            )}
                                                            {isUpdateConnectionError && !isUpdateConnectionLoading && isUpdateConnectionError.length > 0 &&
                                                                <FormHelperText
                                                                    error>Error: {isUpdateConnectionError}</FormHelperText>
                                                            }
                                                        </AnimateButton>
                                                    </Grid>
                                                  ) :
                                                    <Grid item xs={12}>
                                                        <Typography sx={{ width: 1 / 2, mt: 2, mb: 1 }}>
                                                            Perfect! Click 'Test Connection' to finalise connection setup.
                                                        </Typography>
                                                    </Grid>
                                                  }
                                              </Grid>
                                          </form>
                                        )}
                                    </Formik>

                              {/*      <div style={{paddingTop: 10}}></div>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="outlined"
                                            color="primary"
                                            disabled={() => {connectionDetails.iam_role === ''}}
                                            onClick={() => {
                                                getConnectionValidation({
                                                    id: connectionDetails.id,
                                                    aws_account_id: connectionDetails.aws_account_id,
                                                    iam_role: connectionDetails.iam_role,
                                                    validated: connectionDetails.validated,
                                                    company_id: connectionDetails.company_id,
                                                });
                                            }}
                                        >
                                            Test Connection
                                        </Button>
                                    </AnimateButton>*/}

                                </Typography>

                            </Grid>
                        )}
                        {activeStepManualConnection === 5 ? (
                            <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    All steps completed - you&apos;re finished
                                </Typography>
                                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                                    <Box sx={{ flex: "1 1 auto" }} />
                                    <Button onClick={handleResetManualConnection}>Close</Button>
                                </Box>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                                    <Button
                                        color="inherit"
                                        disabled={activeStepManualConnection === 0}
                                        onClick={handleBackManualConnection}
                                        sx={{ mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                    <Box sx={{ flex: "1 1 auto" }} />
                                    <Button onClick={handleNextManualConnection}
                                            disabled={activeStepManualConnection === 1 && !connectionDetails ? "Next" : null}>
                                        {activeStepManualConnection === stepsManual.length - 1 ? "Finish" : "Next"}
                                    </Button>
                                </Box>
                            </React.Fragment>
                        )}
                    </Box>
                </Modal>
            </Grid>
            <Grid item />
            <Grid item xs={12} md={12} lg={12}>
                <MainCard sx={{ mt: 2 }} content={false}>
                    <ConnectionsTable />
                </MainCard>
            </Grid>
        </Grid>
    );
};

export default ConnectionsDefault;
