import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import TermsOfService from "../pages/extra-pages/TermsOfService";
import PrivacyPolicy from "../pages/extra-pages/PrivacyPolicy";

// render - login
// const LandingPage = Loadable(lazy(() => import('pages/landing-page/index')));
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const AuthRegistered = Loadable(lazy(() => import('pages/authentication/Registered')));
const ForgotPassword = Loadable(lazy(() => import('pages/authentication/ForgotPassword')));


// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: 'login',
            element: <AuthLogin />
        },
        {
            path: 'register',
            element: <AuthRegister />
        },
        {
            path: 'registered',
            element: <AuthRegistered />
        },
        {
            path: 'forgot-password',
            element: <ForgotPassword />
        },
        {
            path: 'tos',
            element: <TermsOfService />
        },
        {
            path: 'privacy',
            element: <PrivacyPolicy />
        },
    ]
};

export default LoginRoutes;
