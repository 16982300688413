// material-ui
import { useTheme } from "@mui/material/styles";
import cakebiLogoWhite from 'assets/images/icons/cakebi-w.png';
import cakebiLogoBlue from 'assets/images/icons/cakebi.png';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */
export const Logo = () => {
    const theme = useTheme();
    return <><img src={cakebiLogoWhite} alt="Cake BI" width="100" /></>;
};

export const LogoBlue = () => {
    const theme = useTheme();
    return <><img src={cakebiLogoBlue} alt="Cake BI" width="200" /></>;
};
export default Logo;
