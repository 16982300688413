// material-ui
import {Grid, Stack, Typography} from '@mui/material';

// project import
import AuthWrapper from "../authentication/AuthWrapper";

// ==============================|| SAMPLE PAGE ||============================== //

const TermsOfService = () => (
    <AuthWrapper>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                    <Typography variant="h2">Terms of Service</Typography>
                </Stack>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h4">
                    1. Introduction
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    Welcome to CakeBI! These Terms of Service ("Terms") govern your use of our website, products, and services. By accessing or using our website or services, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use our website or services.
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h4">
                    2. User Responsibilities
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    2.1 User Accounts:
                </Typography>
                <Typography variant="body2">
                    To access certain features of our website or services, you may need to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
                </Typography>
                <br/>
                <Typography variant="body2">
                    2.2 Accuracy of Information:
                </Typography>
                <Typography variant="body2">
                    You agree to provide accurate and current information when using our website or services. We reserve the right to terminate or suspend your account if we suspect that the information you provide is inaccurate, incomplete, or not current.
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h4">
                    3. Intellectual Property
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    3.1 Ownership:
                </Typography>
                <Typography variant="body2">
                    All content and materials available on our website or through our services, including but not limited to text, graphics, logos, images, and software, are the property of CakeBI and are protected by intellectual property laws.
                </Typography>
                <br/>
                <Typography variant="body2">
                    3.2 Use of Materials:
                </Typography>
                <Typography variant="body2">
                    You may not reproduce, distribute, modify, display, perform, or otherwise use any materials from our website or services without the prior written consent of CakeBI.
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h4">
                    4. Privacy Policy
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    Our Privacy Policy governs the collection, use, and disclosure of your personal information. Please review our Privacy Policy to understand how we collect and use your information.
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h4">
                    5. Limitation of Liability
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    To the fullest extent permitted by law, CakeBI shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from:
                </Typography>
                <Typography variant="body2">
                    - Your use or inability to use our website or services.
                </Typography>
                <Typography variant="body2">
                    - Any unauthorized access to or use of our servers and/or any personal information stored therein.
                </Typography>
                <Typography variant="body2">
                    - Any interruption or cessation of transmission to or from our website or services.
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h4">
                    6. Governing Law
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    These Terms are governed by and construed in accordance with the laws of England and Wales. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in England and Wales.
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h4">
                    7. Changes to Terms
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    CakeBI reserves the right to modify or update these Terms at any time. We will notify you of any changes by posting the new Terms on this page. Your continued use of our website or services after any changes constitutes your acceptance of the new Terms.
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body2">
                    If you have any questions about these Terms, please contact us at: info@cakebi.com
                </Typography>
            </Grid>
        </Grid>
    </AuthWrapper>
);

export default TermsOfService;
