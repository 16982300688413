// material-ui
import {
    Avatar,
    Button,
    Grid,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
    Dialog,

} from "@mui/material";
import TextField from '@mui/material/TextField';
import React, {useCallback, useEffect, useState} from 'react';

// project import
import MainCard from "components/MainCard";
import SingleFileAutoSubmit from "./SingleFileAutoSubmit";
import * as antIcons from "@ant-design/icons";
import {useGetMeQuery, useUpdateUserMutation} from "../../store/api";
import { useTheme } from "@mui/material/styles";

// ==============================|| USERS - DEFAULT ||============================== //

const AdminAccountSettingsDefault = () => {
    const theme = useTheme();

    // get current user
    let {data: Me} = useGetMeQuery();
    const [companyId, setCompanyId] = useState(null);
    useEffect(() => {
        if (Me) { setCompanyId(Me.company_id); }
    }, [Me])

    const [userData, setUserData] = useState({id: '', name: '', email: '', role: '', company_id: '', password: '', passwordConfirm: ''})
    useEffect(() => {
        if (Me) {
            setUserData({
                id: Me.id, //static
                name: Me.name.toString(),
                email: Me.email,
                role: Me.role, //static
                company_id: companyId, //static
                password: null,
                passwordConfirm: null,
            });
        }
    }, [Me, companyId])

    // set up open[value] vars here
    const [openName, setOpenName] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const [openPassword, setOpenPassword] = useState(false);

    // update user data
    const [updateUser, {isLoading}] = useUpdateUserMutation();

    const onNameUpdate = useCallback((dataToUse) => {
        setUserData(prevState => ({...prevState, name: dataToUse.target.value}))
    }, []);
    const onEmailUpdate = useCallback((dataToUse) => {
        setUserData(prevState => ({...prevState, email: dataToUse.target.value}))
    }, []);
    const onPasswordUpdate = useCallback((dataToUse) => {
        setUserData(prevState => ({...prevState, password: dataToUse.target.value}))
    }, []);
    const onPasswordConfirmUpdate = useCallback((dataToUse) => {
        setUserData(prevState => ({...prevState, passwordConfirm: dataToUse.target.value}))
    }, []);

    // open pop-up windows
    const handleNameOpen = () => { setOpenName(true); };
    const handleEmailOpen = () => { setOpenEmail(true); };
    const handlePasswordOpen = () => { setOpenPassword(true); setPasswordError(null); };

    // save
    const handleNameSave = () => {
        updateUser({ id: Me.id, user_data: userData });
        setOpenName(false); };
    const handleEmailSave = () => {
        updateUser({ id: Me.id, user_data: userData });
        setOpenEmail(false); };

    const [passwordError, setPasswordError] = useState("")
    const handlePasswordSave = () => {
        if (userData.password === userData.passwordConfirm) {
            console.log("Data: ", userData);
            debugger
            updateUser({id: Me.id, user_data: userData});
            setOpenPassword(false);
        }
        else
        {
            userData.passwordConfirm = null;
            setPasswordError("Passwords do not match");
        }
    };

    // close pop-up windows
    const handleNameClose = () => { setOpenName(false); };
    const handleEmailClose = () => { setOpenEmail(false); };
    const handlePasswordClose = () => { setOpenPassword(false); };

    return (
        <div>

            {/* Dialog for Name Start */}
            <Dialog open={openName} onClose={handleNameClose}>
                <DialogTitle>Update Name</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Change your name here:
                    </DialogContentText>
                    <TextField defaultValue={userData.name} onChange={onNameUpdate} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNameClose}>Cancel</Button>
                    <Button onClick={handleNameSave}>Save</Button>
                </DialogActions>
            </Dialog>
            {/* Dialog for Name Close */}

            {/* Dialog for Email Start */}
            <Dialog open={openEmail} onClose={handleEmailClose}>
                <DialogTitle>Update Email</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Change your email here:
                    </DialogContentText>
                    <TextField defaultValue={userData.email} onChange={onEmailUpdate} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEmailClose}>Cancel</Button>
                    <Button onClick={handleEmailSave}>Save</Button>
                </DialogActions>
            </Dialog>
            {/* Dialog for Email Close */}

            {/* Dialog for Password Start */}
            <Dialog open={openPassword} onClose={handlePasswordClose}>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Input your new password:
                    </DialogContentText>
                    <TextField type={'password'} onChange={onPasswordUpdate} />
                </DialogContent>

                <DialogContent>
                    <DialogContentText>
                        Confirm your new password:
                    </DialogContentText>
                    <TextField type={'password'} onChange={onPasswordConfirmUpdate} />
                </DialogContent>

                {passwordError ?
                    <DialogContent>
                        <DialogContentText> Passwords do not match </DialogContentText>
                    </DialogContent>
                :
                    <></>
                }

                <DialogActions>
                    <Button onClick={handlePasswordClose}>Cancel</Button>
                    <Button onClick={handlePasswordSave}>Save</Button>
                </DialogActions>

            </Dialog>
            {/* Dialog for Password Close */}


            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                {/* row 3 */}
                <Grid item xs={12} md={12} lg={12}>
                    <MainCard sx={{ mt: 2 }} content={false}>
                        <List component="nav">

                            <ListItemButton divider onClick={handleNameOpen}>
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            bgcolor: theme.palette.primary.lighter
                                        }}
                                    >
                                        <antIcons.SmileTwoTone twoToneColor={theme.palette.primary.main}  />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={<Typography variant="subtitle1">Update Name</Typography>} />
                                <ListItemSecondaryAction>
                                    <Stack alignItems="flex-end">
                                        <Typography variant="subtitle1" noWrap>
                                            {userData.name}
                                        </Typography>
                                    </Stack>
                                </ListItemSecondaryAction>
                            </ListItemButton>

                            <ListItemButton divider onClick={handleEmailOpen}>
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            bgcolor: theme.palette.primary.lighter
                                        }}
                                    >
                                        <antIcons.MailTwoTone twoToneColor={theme.palette.primary.main} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={<Typography variant="subtitle1">Update Email</Typography>} />
                                <ListItemSecondaryAction>
                                    <Stack alignItems="flex-end">
                                        <Typography variant="subtitle1" noWrap>
                                            {userData.email}
                                        </Typography>
                                    </Stack>
                                </ListItemSecondaryAction>
                            </ListItemButton>

                            <ListItemButton onClick={handlePasswordOpen}>
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            bgcolor: theme.palette.primary.lighter
                                        }}
                                    >
                                        <antIcons.LockTwoTone twoToneColor={theme.palette.primary.main} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={<Typography variant="subtitle1">Update Password</Typography>} />
                                <ListItemSecondaryAction>
                                    <Stack alignItems="flex-end">
                                        <Typography variant="subtitle1" noWrap />
                                    </Stack>
                                </ListItemSecondaryAction>
                            </ListItemButton>

                        </List>
                    </MainCard>
                </Grid>
            </Grid>
        </div>
    );
};

export default AdminAccountSettingsDefault;
