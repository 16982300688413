import React, {useCallback, useEffect, useState} from "react";
import { useDropzone } from "react-dropzone";
import {useUpdateCompanyLogoMutation} from "../../store/api";

function SingleFileAutoSubmit(companyId) {
    const [addLogo, { isLoading }] = useUpdateCompanyLogoMutation();
    const onDrop = useCallback(
        (acceptedFiles) => {
            addLogo({ id: companyId.companyId, imageFile: acceptedFiles[0] });
        },
        [addLogo]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? <p>Upload</p> : <p>Drag and drop or click here to upload a file</p>}
        </div>
    );
}

export default SingleFileAutoSubmit;
