// material-ui
import {Grid, Stack, Typography} from '@mui/material';

// project import
import AuthWrapper from "../authentication/AuthWrapper";

// ==============================|| SAMPLE PAGE ||============================== //

const PrivacyPolicy = () => (
    <AuthWrapper>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                    <Typography variant="h2">Privacy Policy</Typography>
                </Stack>
                <Typography variant="body2">
                    This Privacy Policy explains how CakeBI collects, uses, and protects information that we collect from our users
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h4">
                    1. Information We Collect
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    1.1 Personal Information:
                </Typography>
                <Typography variant="body2">
                    We may collect personal information such as names, email addresses, and other contact details when voluntarily submitted by our users.
                </Typography>
                <br/>
                <Typography variant="body2">
                    1.2 Non-Personal Information:
                </Typography>
                <Typography variant="body2">
                    We may also collect non-personal information, such as browser types, operating systems, and the date and time of visits. This information is used to enhance the user experience on our website.
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h4">
                    2. How We Use Your Information
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    2.1 Personal Information:
                </Typography>
                <Typography variant="body2">
                    We may use your personal information to communicate with you, process transactions, and provide you with information or services you have requested.
                </Typography>
                <br/>
                <Typography variant="body2">
                    2.2 Non-Personal Information:
                </Typography>
                <Typography variant="body2">
                    Non-personal information may be used for analytics, to improve our website and services, and for other internal business purposes.
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h4">
                    3. Cookies and Tracking Technologies
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    We may use cookies and similar tracking technologies to collect and track information about your use of our website. This information may include IP addresses, browser types, and pages visited. You may disable cookies in your browser settings, but this may affect the functionality of our website.
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h4">
                    4. Sharing Your Information
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    We do not sell, trade, or rent your personal information to third parties. We may share your information with trusted third parties who assist us in operating our website or providing services, as long as those parties agree to keep this information confidential.
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h4">
                    5. Security
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the internet is 100% secure, and we cannot guarantee the absolute security of your information.
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h4">
                    6. Your Choices
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    You may choose not to provide certain personal information, but this may limit your ability to use certain features of our website or services.
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h4">
                    7. Changes to this Privacy Policy
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    We reserve the right to modify or update this Privacy Policy at any time. Changes will be posted on this page, and your continued use of our website or services after any changes constitute your acceptance of the new Privacy Policy.
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body2">
                    If you have any questions about this Privacy Policy, please contact us at info@cakebi.com
                </Typography>
            </Grid>
        </Grid>
    </AuthWrapper>
);

export default PrivacyPolicy;
