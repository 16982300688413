// ==============================|| THEME CONFIG  ||============================== //

const config = {
    defaultPath: '/',
    fontFamily: `'Public Sans', sans-serif`,
    i18n: 'en',
    miniDrawer: false,
    container: true,
    mode: 'light',
    presetColor: 'default',
    themeDirection: 'ltr'
};

export const API_URL = process.env.REACT_APP_API_URL;
export const ROOT_URL = process.env.REACT_APP_ROOT_URL;
export const USER_URL = process.env.REACT_APP_USER_URL;
export const ROLE_URL  = process.env.REACT_APP_ROLE_URL;


export default config;
export const drawerWidth = 260;

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';
