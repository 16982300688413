import { castleApi } from "../api";
import { castleClientApi } from "../clientApi";

const api = {
    // Add the generated reducer as a specific top-level slice
    [castleApi.reducerPath]: castleApi.reducer,
    [castleClientApi.reducerPath]: castleClientApi.reducer
};

export default api;
