import axios from 'axios';

import { API_URL, ROOT_URL } from 'config';
const register = (name, email, password, passwordConfirm, company) => {
    return axios.post(API_URL + 'auth/register/create', {
        name,
        email,
        password,
        passwordConfirm,
        company,
        'verified': false,
        'role': 3
    });
};

const login = (email, password) => {
    return axios
        .post(API_URL + 'auth/login', {
            email,
            password
        })
        .then((response) => {
            if (response.data.access_token) {
                localStorage.setItem('user', JSON.stringify(response.data));
                document.cookie = "access_token =; Max-Age=0";
                document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            }
            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem('user');
    setTimeout(() => window.location.replace('/login'), 500);
};

const forgot = (email) => {
    return axios
        .post(API_URL + 'auth/forgot-password/admin', {
            email
        })
        .then((response) => {
            return response.data;
        });
};

const authService = {
    register,
    login,
    logout,
    forgot
};

export default authService;
