// material-ui
import {
    Avatar,
    Button,
    Grid,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
    Dialog, InputLabel, OutlinedInput, ImageList, IconButton

} from "@mui/material";
import TextField from '@mui/material/TextField';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import React, {useCallback, useEffect, useState} from 'react';

// project import
import MainCard from "components/MainCard";
import SingleFileAutoSubmit from "./SingleFileAutoSubmit";
import {
    BgColorsOutlined,
    CrownTwoTone,
    HomeTwoTone,
    IdcardTwoTone, MailTwoTone,
    PictureTwoTone,
    TagsTwoTone
} from "@ant-design/icons";
import {HexColorInput, HexColorPicker} from "react-colorful";
import {
    useGetMeQuery,
    useUpdateCompanyMutation,
    useGetCompanyQuery,
    useLazyGetValidateDomainNameQuery
} from "../../store/api";
import { API_URL, ROOT_URL } from 'config';
import Loader from "../../components/Loader";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

// ==============================|| USERS - DEFAULT ||============================== //
const SettingsDefault = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    // get current user company id
    let {data: Me} = useGetMeQuery();
    const [companyId, setCompanyId] = useState(null);
    useEffect(() => {
        if (Me) { setCompanyId(Me.company_id); }
    }, [Me])

    // get current company data
    const {data: myCompanyData, companyError, isLoading: companyLoading} = useGetCompanyQuery(companyId, {skip : !companyId});
    const [validateDomain, { data: validateResponse, error, isLoading: validateLoading}] = useLazyGetValidateDomainNameQuery();

    const [companyData, setCompanyData] = useState({
        logo_path: '', primary_colour: '', secondary_colour: '', title: '', name: '', domain_name: '',
        smtp_host: '', smtp_port: -1, smtp_username: '', smtp_password: '', email_from: '', available_user_licences: 0, total_user_licences: 0
    });
    useEffect(() => {
        if (myCompanyData) {
            setCompanyData({
                logo_path: myCompanyData.logo_path ? myCompanyData.logo_path.toString(): "",
                primary_colour: myCompanyData.primary_colour ? myCompanyData.primary_colour.toString() : "",
                secondary_colour: myCompanyData.secondary_colour ? myCompanyData.secondary_colour.toString() : "",
                title: myCompanyData.title ? myCompanyData.title.toString() : "",

                name: myCompanyData.name ? myCompanyData.name.toString() : "",
                domain_name: myCompanyData.domain_name ? myCompanyData.domain_name.toString() : "",
                smtp_host: myCompanyData.smtp_host ? myCompanyData.smtp_host.toString() : "",
                smtp_port: myCompanyData.smtp_port ? myCompanyData.smtp_port : -1,
                smtp_username: myCompanyData.smtp_username ? myCompanyData.smtp_username.toString() : "",
                smtp_password: "",
                available_user_licences: myCompanyData.available_user_licences ? myCompanyData.available_user_licences : -1,
                total_user_licences: myCompanyData.total_user_licences ? myCompanyData.total_user_licences : -1,
                email_from: myCompanyData.email_from ? myCompanyData.email_from.toString() : "cakebi@admin.com",
            })
        }
    }, [myCompanyData])

    // set up open[value] vars here
    const [openLogo, setOpenLogo] = useState(false);
    const [openPrimaryColour, setOpenPrimaryColour] = useState(false);
    const [openSecondaryColour, setOpenSecondaryColour] = useState(false);
    const [openDomainName, setOpenDomainName] = useState(false);
    const [enteredDomainName, setEnteredDomainName] = useState(null);
    const [openTitle, setOpenTitle] = useState(false);
    const [openName, setOpenName] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const [openLicences, setOpenLicences] = useState(false);

    // update company data
    const [updateCompany, {isLoading, isError}] = useUpdateCompanyMutation();
    const onPrimaryColourUpdate = useCallback((dataToUse) => {
        setCompanyData(prevState => ({...prevState, primary_colour: dataToUse}))
    }, []);
    const onSecondaryColourUpdate = useCallback((dataToUse) => {
        setCompanyData(prevState => ({...prevState, secondary_colour: dataToUse}))
    }, []);
    const onTitleChange = useCallback((dataToUse) => {
        setCompanyData(prevState => ({...prevState, title: dataToUse.target.value}))
    }, []);
    const onNameChange = useCallback((dataToUse) => {
        setCompanyData(prevState => ({...prevState, name: dataToUse.target.value}))
    }, []);
    const onSmtpHostChange = useCallback((dataToUse) => {
        setCompanyData(prevState => ({...prevState, smtp_host: dataToUse.target.value}))
    }, []);
    const onSmtpPortChange = useCallback((dataToUse) => {
        setCompanyData(prevState => ({...prevState, smtp_port: dataToUse.target.value}))
    }, []);
    const onSmtpUsernameChange = useCallback((dataToUse) => {
        setCompanyData(prevState => ({...prevState, smtp_username: dataToUse.target.value}))
    }, []);
    const onSmtpPasswordChange = useCallback((dataToUse) => {
        setCompanyData(prevState => ({...prevState, smtp_password: dataToUse.target.value}))
    }, []);
    const onEmailFromChange = useCallback((dataToUse) => {
        setCompanyData(prevState => ({...prevState, email_from: dataToUse.target.value}))
    }, []);

    React.useEffect(() => {
        if (validateResponse && !error) {
            // The DNS name has been validated. Let's save it.
            setCompanyData(prevState => ({...prevState, domain_name: enteredDomainName}))
            updateCompany({ id: companyId, data: {...companyData, domain_name: enteredDomainName} })
        }
    },[validateResponse])
    const onDomainNameChange = useCallback((dataToUse) => {
        setEnteredDomainName(dataToUse.target.value);
    }, []);

    // open pop-up windows
    const handleLogoOpen = () => { setOpenLogo(true); };
    const handlePrimaryColourOpen = () => { setOpenPrimaryColour(true); };
    const handleSecondaryColourOpen = () => { setOpenSecondaryColour(true); };
    const handleDomainNameOpen = () => { setOpenDomainName(true); };
    const handleTitleOpen = () => { setOpenTitle(true); };
    const handleNameOpen = () => { setOpenName(true); };
    const handleEmailOpen = () => { setOpenEmail(true); };
    const handleLicencesOpen = () => { setOpenLicences(true); };

    // save
    const handlePrimaryColourSave = () => {
        updateCompany({ id: companyId, data: companyData });
        setOpenPrimaryColour(false); };
    const handleSecondaryColourSave = () => {
        updateCompany({ id: companyId, data: companyData });
        setOpenSecondaryColour(false); };
    const handleDomainValidate = async () => {
        await validateDomain({id: companyId, domainName: enteredDomainName});
    };
    const handleTitleSave = () => {
        updateCompany({ id: companyId, data: companyData })
        setOpenTitle(false); };
    const handleNameSave = () => {
        updateCompany({ id: companyId, data: companyData })
        setOpenName(false); };
    const handleEmailSave = () => {
        updateCompany({ id: companyId, data: companyData })
        setOpenEmail(false);
    };


    // close pop-up windows
    const handleLogoClose = () => { setOpenLogo(false); };
    const handlePrimaryColourClose = () => { setOpenPrimaryColour(false); };
    const handleSecondaryColourClose = () => { setOpenSecondaryColour(false); };
    const handleDomainNameClose = () => { setOpenDomainName(false); };
    const handleTitleClose = () => { setOpenTitle(false); };
    const handleNameClose = () => { setOpenName(false); };
    const handleEmailClose = () => { setOpenEmail(false); };
    const handleLicencesClose = () => { setOpenLicences(false); };


    function toggleImage() {
        let element = document.getElementById("lightOrDarkImage");
        let boxOutline = document.getElementById("changeBoxToImage");
        if (element.src.includes("light.png")) {
            element.src = element.src.replace("light", "dark");
            // boxOutline.style.height = "534.5px";
        } else {
            element.src = element.src.replace("dark", "light");
            // boxOutline.style.height = "548px";
        }
    }

    const hostname = window.location.origin

    if (companyLoading) {
        return <> <Loader /> <h1 align={'center'}>Loading</h1> </>
    }

    return (
        <div>
            {validateLoading && <Loader />}
            {/* Dialog for Name */}
            <Dialog open={openName} onClose={handleNameClose}>
                <DialogTitle>Company name</DialogTitle>
                <DialogContent>
                    <DialogContentText>Update the Company's name here</DialogContentText>
                    <br />
                    <TextField defaultValue={companyData.name} onChange={onNameChange} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNameClose}>Cancel</Button>
                    <Button onClick={handleNameSave}>Save</Button>
                </DialogActions>
            </Dialog>

            {/* Dialog for Domain Name */}
            <Dialog open={openDomainName} onClose={handleDomainNameClose}>
                <DialogTitle>
                    <h4>Domain Name</h4>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <h3>Step 1:</h3>
                        Add a new DNS record to your domain using your Domain Name hosting provider's interface.
                        <br />
                        <pre>mysub.mydomain.com - CNAME - {hostname.replace("http://", "").replace("https://", "")} </pre>
                        <br />
                        <h3>Step 2:</h3>
                        Then, add your new DNS address in the text box below. Press Save.
                    </DialogContentText>
                    <br />
                    <TextField defaultValue={companyData.domain_name} onChange={onDomainNameChange} />
                    <br />
                    <br />
                    <DialogContentText>
                        Note: this should be the domain not the full URL; 'https://yourcompany.com' becomes 'yourcompany.com'
                    </DialogContentText>
                    <br />
                    <DialogContentText>
                        {error &&
                            `An error occured validating this domain. Ensure a CNAME record has been created for ${enteredDomainName} to ${hostname
                                .replace("http://", "")
                                .replace("https://", "")}`}
                        {validateResponse &&
                            validateResponse.status === "success" &&
                            `Domain ${enteredDomainName} has been validated. You can now view your portal at https://${enteredDomainName}.`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDomainNameClose}>Close</Button>
                    {(validateResponse && validateResponse === "success") ||
                    !enteredDomainName ||
                    enteredDomainName === companyData.domain_name ? (
                        <Button onClick={handleDomainNameClose} disabled>
                            Save
                        </Button>
                    ) : (
                        <Button onClick={handleDomainValidate}>Save</Button>
                    )}
                </DialogActions>
            </Dialog>

            {/* Dialog for Email */}
            <Dialog open={openEmail} onClose={handleEmailClose}>
                <DialogTitle>Email settings</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add your company's SMTP email details here, this will allow users to receive secure emails from your company
                    </DialogContentText>
                    <br />
                    <div>
                        <span>
                            <Typography>SMTP Host</Typography>
                            <TextField defaultValue={companyData.smtp_host} onChange={onSmtpHostChange} />
                        </span>
                        <br />
                        <span>
                            <Typography sx={{ marginTop: "7px" }}>SMTP Port</Typography>
                            <TextField defaultValue={companyData.smtp_port} onChange={onSmtpPortChange} />
                        </span>
                    </div>

                    <div style={{ padding: "1px" }}>
                        <span>
                            <Typography sx={{ marginTop: "7px" }}>SMTP Username</Typography>
                            <TextField defaultValue={companyData.smtp_username} onChange={onSmtpUsernameChange} />
                        </span>
                        <br />
                        <span>
                            <Typography sx={{ marginTop: "7px" }}>SMTP Password</Typography>
                            <TextField type={"password"} defaultValue={companyData.smtp_password} onChange={onSmtpPasswordChange} />
                        </span>
                    </div>

                    <div>
                        <span>
                            <Typography sx={{ marginTop: "7px" }}>Email From Address</Typography>
                            <TextField defaultValue={companyData.email_from} onChange={onEmailFromChange} />
                        </span>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEmailClose}>Cancel</Button>
                    <Button onClick={handleEmailSave}>Save</Button>
                </DialogActions>
            </Dialog>

            {/* Dialog for Licences */}
            <Dialog open={openLicences} onClose={handleLicencesClose}>
                <DialogTitle>User licences</DialogTitle>
                <DialogContent>
                    <div style={{ padding: "1px" }}>
                        <span>
                            <Typography sx={{ marginTop: "7px" }}>Available licences: {companyData.available_user_licences}</Typography>
                            <DialogContentText>
                                The number of licences you have available to assign to users (every user requires a licence).
                            </DialogContentText>
                        </span>
                        <br />
                        <span>
                            <Typography sx={{ marginTop: "7px" }}>Total Licences: {companyData.total_user_licences}</Typography>
                            <DialogContentText>
                                The total number of licences you have access to.
                            </DialogContentText>
                        </span>

                        <DialogActions>
                            <Button onClick={() => {navigate('/checkout')}}>Buy more licences</Button>
                        </DialogActions>

                    </div>
                </DialogContent>
            </Dialog>

            {/* Dialog for Title */}
            <Dialog open={openTitle} onClose={handleTitleClose}>
                <DialogTitle>Title</DialogTitle>
                <DialogContent>
                    <DialogContentText>Add your company's title here, this is what the users will see in their portal</DialogContentText>
                    <br />
                    <TextField defaultValue={companyData.title} onChange={onTitleChange} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleTitleClose}>Cancel</Button>
                    <Button onClick={handleTitleSave}>Save</Button>
                </DialogActions>
            </Dialog>

            {/* Dialog for Logo */}
            <Dialog open={openLogo} onClose={handleLogoClose}>
                <DialogTitle>Logo</DialogTitle>
                <DialogContent>
                    <DialogContentText>Upload your logo. Ideal size is 400 x 200px.</DialogContentText>
                    <br />
                    <SingleFileAutoSubmit companyId={companyId} logo={companyData.logo_path} onChange={companyData.logo_path} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLogoClose}>Cancel</Button>
                    <Button onClick={handleLogoClose}>Save</Button>
                </DialogActions>
            </Dialog>

            {/* Dialog for Primary Colour */}
            <Dialog open={openPrimaryColour} onClose={handlePrimaryColourClose}>
                <DialogTitle>Primary Colour</DialogTitle>
                <DialogContent>
                    <DialogContentText>Select Primary Colour</DialogContentText>
                    <HexColorPicker color={companyData.primary_colour} onChange={onPrimaryColourUpdate} />
                    <br />
                    <HexColorInput color={companyData.primary_colour} onChange={onPrimaryColourUpdate} />
                    <DialogContentText>Selected colour: {companyData.primary_colour}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePrimaryColourClose}>Cancel</Button>
                    <Button onClick={handlePrimaryColourSave}>Save</Button>
                </DialogActions>
            </Dialog>

            {/* Dialog for Secondary Colour */}
            <Dialog open={openSecondaryColour} onClose={handleSecondaryColourClose}>
                <DialogTitle>Secondary Colour</DialogTitle>
                <DialogContent>
                    <DialogContentText>Select Secondary Colour</DialogContentText>
                    <HexColorPicker color={companyData.secondary_colour} onChange={onSecondaryColourUpdate} />
                    <br />
                    <HexColorInput color={companyData.secondary_colour} onChange={onSecondaryColourUpdate} />
                    <DialogContentText>Selected colour: {companyData.secondary_colour}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSecondaryColourClose}>Cancel</Button>
                    <Button onClick={handleSecondaryColourSave}>Save</Button>
                </DialogActions>
            </Dialog>

            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                <Grid item xs={12} md={12} lg={12}>
                    <MainCard sx={{ mt: 2 }} content={false}>
                        <Typography variant="subtitle" noWrap sx={{ paddingLeft: "20px", marginTop: "15px" }}>
                            Company
                        </Typography>
                        <List
                            component="nav"
                            // sx={{
                            //     px: 0,
                            //     py: 0,
                            //     '& .MuiListItemButton-root': {
                            //         py: 1.5,
                            //         '& .MuiAvatar-root': avatarSX,
                            //         '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                            //     }
                            // }}
                        >
                            <ListItemButton divider onClick={handleNameOpen}>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: theme.palette.primary.lighter }}>
                                        <CrownTwoTone twoToneColor={theme.palette.primary.main} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={<Typography variant="subtitle1">Company name</Typography>} />
                                <ListItemSecondaryAction>
                                    <Stack alignItems="flex-end">
                                        <Typography variant="subtitle1" noWrap>
                                            {companyData.name}
                                        </Typography>
                                    </Stack>
                                </ListItemSecondaryAction>
                            </ListItemButton>

                            <ListItemButton divider onClick={handleDomainNameOpen}>
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            bgcolor: theme.palette.primary.lighter
                                        }}
                                    >
                                        <HomeTwoTone twoToneColor={theme.palette.primary.main} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={<Typography variant="subtitle1">Domain name</Typography>} />
                                <ListItemSecondaryAction>
                                    <Stack alignItems="flex-end">
                                        <Typography variant="subtitle1" noWrap>
                                            {companyData.domain_name}
                                        </Typography>
                                    </Stack>
                                </ListItemSecondaryAction>
                            </ListItemButton>

                            <ListItemButton divider onClick={handleEmailOpen}>
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            bgcolor: theme.palette.primary.lighter
                                        }}
                                    >
                                        <MailTwoTone twoToneColor={theme.palette.primary.main} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={<Typography variant="subtitle1">Email settings</Typography>} />
                                <ListItemSecondaryAction>
                                    <Stack alignItems="flex-end">
                                        <Typography variant="subtitle1" noWrap>
                                            {" "}
                                        </Typography>
                                    </Stack>
                                </ListItemSecondaryAction>
                            </ListItemButton>

                            <ListItemButton onClick={handleLicencesOpen}>
                                <ListItemAvatar>
                                    <Avatar
                                      sx={{
                                          bgcolor: theme.palette.primary.lighter
                                      }}
                                    >
                                        <TagsTwoTone twoToneColor={theme.palette.primary.main} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={<Typography variant="subtitle1">User licences</Typography>} />
                                <ListItemSecondaryAction>
                                    <Stack alignItems="flex-end">
                                        <Typography variant="subtitle1" noWrap>
                                            {" "}
                                        </Typography>
                                    </Stack>
                                </ListItemSecondaryAction>
                            </ListItemButton>
                        </List>
                    </MainCard>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
                <MainCard sx={{ mt: 2 }} content={false}>
                    <Typography variant="subtitle" noWrap sx={{ paddingLeft: "20px", marginTop: "20px" }}>
                        Branding
                    </Typography>
                    <List
                        component="nav"
                        // sx={{
                        //     px: 0,
                        //     py: 0,
                        //     '& .MuiListItemButton-root': {
                        //         py: 1.5,
                        //         '& .MuiAvatar-root': avatarSX,
                        //         '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                        //     }
                        // }}
                    >
                        <ListItemButton divider onClick={handleTitleOpen}>
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        bgcolor: theme.palette.primary.lighter
                                    }}
                                >
                                    <IdcardTwoTone twoToneColor={theme.palette.primary.main} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={<Typography variant="subtitle1">Title</Typography>} />
                            <ListItemSecondaryAction>
                                <Stack alignItems="flex-end">
                                    <Typography variant="subtitle1" noWrap>
                                        {companyData.title}
                                    </Typography>
                                </Stack>
                            </ListItemSecondaryAction>
                        </ListItemButton>

                        <ListItemButton divider onClick={handleLogoOpen}>
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        bgcolor: theme.palette.primary.lighter
                                    }}
                                >
                                    <PictureTwoTone twoToneColor={theme.palette.primary.main} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={<Typography variant="subtitle1">Logo</Typography>} />
                            <ListItemSecondaryAction>
                                <Stack alignItems="flex-end">
                                    <Typography variant="subtitle1" noWrap>
                                        {companyData.logo_path.split("/")[4]}
                                    </Typography>
                                </Stack>
                            </ListItemSecondaryAction>
                        </ListItemButton>

                        <ListItemButton divider onClick={handlePrimaryColourOpen}>
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        color: companyData.primary_colour,
                                        bgcolor: theme.palette.primary.lighter
                                    }}
                                >
                                    <BgColorsOutlined />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={<Typography variant="subtitle1">Primary Colour</Typography>} />
                            <ListItemSecondaryAction>
                                <Stack alignItems="flex-end">
                                    <Typography variant="subtitle1" noWrap>
                                        {companyData.primary_colour}
                                    </Typography>
                                </Stack>
                            </ListItemSecondaryAction>
                        </ListItemButton>

                        <ListItemButton onClick={handleSecondaryColourOpen}>
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        color: companyData.secondary_colour,
                                        bgcolor: theme.palette.primary.lighter
                                    }}
                                >
                                    <BgColorsOutlined />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={<Typography variant="subtitle1">Secondary Colour</Typography>} />
                            <ListItemSecondaryAction>
                                <Stack alignItems="flex-end">
                                    <Typography variant="subtitle1" noWrap>
                                        {companyData.secondary_colour}
                                    </Typography>
                                </Stack>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                    </List>
                </MainCard>
            </Grid>

            <div style={{ paddingTop: "40px" }}></div>

            <Typography variant={"h3"}>User page preview</Typography>
            <Typography variant={"body2"}>Toggle the theme to see how your user page will look</Typography>

            <Button
                disableElevation
                disabled={false}
                fullWidth
                size="large"
                type="submit"
                variant="outlined"
                color="primary"
                onClick={toggleImage}
                style={{ marginBottom: "20px", marginTop: "20px", width: "300px" }}
            >
                Toggle theme
            </Button>

            <div style={{ display: "flex" }}>
                <div
                    id={"changeBoxToImage"}
                    style={{
                        border: "1px solid black",
                        borderRadius: "15px",
                        width: "950px",
                        minHeight: "650px", // Increased to account for the browser bar
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        overflow: "hidden",
                        position: "relative"
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            height: "25px",
                            backgroundColor: "#f0f0f0",
                            borderTopLeftRadius: "15px",
                            borderTopRightRadius: "15px",
                            position: "absolute",
                            top: 0,
                            display: "flex",
                            alignItems: "center",
                            padding: "0 10px",
                            justifyContent: "space-between" // This spreads out the child elements
                        }}
                    >
                        {/* Faux browser buttons */}
                        <div style={{ display: "flex", gap: "4px" }}>
                            <div
                                style={{
                                    width: "12px",
                                    height: "12px",
                                    borderRadius: "50%",
                                    backgroundColor: "#FF605C"
                                }}
                            ></div>
                            <div
                                style={{
                                    width: "12px",
                                    height: "12px",
                                    borderRadius: "50%",
                                    backgroundColor: "#FFBD44"
                                }}
                            ></div>
                            <div
                                style={{
                                    width: "12px",
                                    height: "12px",
                                    borderRadius: "50%",
                                    backgroundColor: "#00CA4E"
                                }}
                            ></div>
                        </div>

                        {/* Faux URL bar */}
                        <div
                            style={{
                                width: "60%",
                                height: "16px",
                                backgroundColor: "#E8E8E8",
                                borderRadius: "10px"
                            }}
                        ></div>
                    </div>

                    {/* Your content starts here, 25px below the top to account for the browser bar */}
                    <div style={{ marginTop: "25px", height: "575px", overflowY: "auto" }}>
                        <div
                            style={{
                                float: "left",
                                height: "65px",
                                width: "65px",
                                paddingLeft: "13px",
                                paddingTop: "13px"
                            }}
                        >
                            <IconButton edge="start" color="secondary" sx={{ color: companyData.secondary_colour }}>
                                <MenuOutlinedIcon sx={{ fontSize: 32 }} />
                            </IconButton>
                        </div>

                        <div
                            style={{
                                backgroundColor: companyData.primary_colour,
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingBottom: 10,
                                height: 65
                            }}
                        >
                            <br />
                            <div align={"center"} style={{ marginTop: "-38px" }}>
                                <h1 style={{ color: companyData.secondary_colour }}> {companyData.title} </h1>
                            </div>
                            <div>
                                <img
                                    src={`${ROOT_URL}${companyData.logo_path}`} alt={""}
                                    style={{ float: "right", maxHeight: "50px", marginTop: companyData.title.replaceAll(" ", "") !== "" ? "-69px" : "0px" }}
                                />
                            </div>
                        </div>

                        <div align={"left"} style={{ backgroundColor: "white", height: "100px" }}>
                            <br />
                            <img
                                id="lightOrDarkImage"
                                src="../../../light.png"
                                alt="here it is"
                                style={{ marginTop: "-24px", width: "948px" }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingsDefault;
