import React, {useCallback, useEffect, useState} from "react";
import { useDropzone } from "react-dropzone";
import {useUpdateCompanyLogoMutation} from "../../store/api";

function SingleFileAutoSubmit(companyId) {
    const [addLogo, { isLoading }] = useUpdateCompanyLogoMutation();
    const onDrop = useCallback(
        (acceptedFiles) => {
            addLogo({ id: companyId.companyId, imageFile: acceptedFiles[0] });
        },
        [addLogo]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div>
            <div {...getRootProps()}>
                {isDragActive ? <p>Upload file</p> : <p>Drag and drop or click <b style={{ color: '#5864ff'}}> here </b> to upload a file</p>}
                <input {...getInputProps()}/>
            </div>
        </div>
    );
}

export default SingleFileAutoSubmit;
