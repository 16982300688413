// project import
import users from './users';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [users]
};

export default menuItems;
