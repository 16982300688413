// material-ui
import {Box, Button, Link, useMediaQuery} from '@mui/material';

// project import
import Profile from './Profile';
import MobileSection from './MobileSection';
import * as antIcons from "@ant-design/icons";
import {USER_URL} from "../../../../config";
import React, {useEffect, useState} from "react";
import {useGetCompanyQuery, useGetMeQuery} from "../../../../store/api";
import TextField from "@mui/material/TextField";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    // get current user company id
    let {data: Me} = useGetMeQuery();
    const [companyId, setCompanyId] = useState(null);
    useEffect(() => {
        if (Me) { setCompanyId(Me.company_id); }
    }, [Me])

    // get current company data
    const {data: myCompanyData} = useGetCompanyQuery(companyId, {skip : !companyId});
    const [companyData, setCompanyData] = useState({alias: '', domain_name: ''});
    useEffect(() => {
        if (myCompanyData) {
            setCompanyData({
                alias: myCompanyData?.alias,
                domain_name: myCompanyData?.domain_name
            });
        }
    }, [myCompanyData])

    return (
        <>

            {matchesXs && <Box sx={{
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap',
                overflow: 'hidden',
                justifyContent: 'flex-end',
                ml: 1
            }}>
                {/*<Button
                    component={Link}
                    href={companyData.domain_name !== "" ? companyData.domain_name : (USER_URL.toString() + companyData.alias)}
                    target="_blank"
                    disableRipple
                    color="secondary"
                    title="User Portal"
                    sx={{color: 'text.primary', bgcolor: 'grey.100', mr: 1}}
                    startIcon={<antIcons.AppstoreOutlined/>}
                />*/}

                {companyData.domain_name === "" ?
                        <Button
                            component={Link}
                            target="_blank"
                            href={`${USER_URL.toString() + companyData.alias}`}
                            disableRipple
                            color="secondary"
                            title="User Portal"
                            sx={{color: 'text.primary', bgcolor: 'grey.100', mr: 1}}
                            startIcon={<antIcons.AppstoreOutlined/>}
                        />
                    :
                        <Button
                            component={Link}
                            target="_blank"
                            href={`https://${companyData.domain_name}`}
                            disableRipple
                            color="secondary"
                            title="User Portal"
                            sx={{color: 'text.primary', bgcolor: 'grey.100', mr: 1}}
                            startIcon={<antIcons.AppstoreOutlined/>}
                        />
                }



                <Button
                    component={Link}
                    href="https://cloudavian.com"
                    target="_blank"
                    disableRipple
                    color="secondary"
                    title="Cloudavian"
                    sx={{ color: 'text.primary', bgcolor: 'grey.100' }}
                    startIcon={<antIcons.CloudOutlined />}
                />

            </Box>}

            {!matchesXs &&
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    overflow: 'hidden',
                    justifyContent: 'flex-end',
                    ml: matchesXs ? 1 : 0
                }}>
                    {companyData.domain_name === "" ?
                        <Button
                            component={Link}
                            target="_blank"
                            href={`${USER_URL.toString() + companyData.alias}`}
                            disableRipple
                            color="secondary"
                            title="User Portal"
                            sx={{color: 'text.primary', bgcolor: 'grey.100', mr: 1}}
                            startIcon={<antIcons.AppstoreOutlined/>}
                        > User Portal </Button>
                        :
                        <Button
                            component={Link}
                            target="_blank"
                            href={`https://${companyData.domain_name}`}
                            disableRipple
                            color="secondary"
                            title="User Portal"
                            sx={{color: 'text.primary', bgcolor: 'grey.100', mr: 1}}
                            startIcon={<antIcons.AppstoreOutlined/>}
                        > User Portal </Button>
                    }

                    <Button
                        component={Link}
                        href="https://cloudavian.com"
                        target="_blank"
                        disableRipple
                        color="secondary"
                        title="Cloudavian"
                        sx={{ color: 'text.primary', bgcolor: 'grey.100' }}
                        startIcon={<antIcons.CloudOutlined />}
                    >
                        Cloudavian
                    </Button>

                </Box>
            }
            {<Profile />}
            {/*{matchesXs && <MobileSection />}*/}
        </>
    );
};

export default HeaderContent;
