import React, { useState } from 'react';
// material-ui
import { Grid } from "@mui/material";

// project import
import DashboardTable from './DashboardsTable';
import MainCard from 'components/MainCard';

// ==============================|| USERS - DEFAULT ||============================== //

const DashboardsDefault = () => {

    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            {/* row 1 */}
            {/*<Grid item xs={12} sm={6} md={4} lg={4}>
                <AnalyticEcommerce title="Total Dashboards" count="36" percentage={59.3} extra="35" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <AnalyticEcommerce title="Total Active Dashboards This Month" count="50" percentage={70.5} extra="80" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <AnalyticEcommerce title="Total Requests" count="1,800" percentage={27.4} isLoss color="warning" extra="13" />
            </Grid>*/}

            <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />

            {/* row 3 */}
            <Grid item xs={12} md={12} lg={12}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item />
                </Grid>
                <MainCard sx={{ mt: 2 }} content={false}>
                    <DashboardTable />
                </MainCard>
            </Grid>
        </Grid>
    );
};

export default DashboardsDefault;
