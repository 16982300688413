// assets
import {
    ApiTwoTone,
    DashboardTwoTone,
    HomeTwoTone,
    SettingTwoTone,
    SmileTwoTone,

} from '@ant-design/icons';

// icons
const icons = {
    ApiTwoTone,
    DashboardTwoTone,
    HomeTwoTone,
    SettingTwoTone,
    SmileTwoTone,
};
// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
    id: 'users',
    type: 'group',
    children: [
        {
            id: 'home1',
            title: 'Home',
            type: 'item',
            url: '/',
            icon: icons.HomeTwoTone,
            twoToneColor: '#efc402',
        },
        {
            id: 'connections1',
            title: 'AWS Connections',
            type: 'item',
            url: '/connections',
            icon: icons.ApiTwoTone,
            twoToneColor: '#efc402',
        },
        {
            id: 'users1',
            title: 'Users',
            type: 'item',
            url: '/user',
            icon: icons.SmileTwoTone,
            twoToneColor: '#efc402',
        },
        {
            id: 'dashboards1',
            title: 'Dashboards',
            type: 'item',
            url: '/bi-dashboards',
            icon: icons.DashboardTwoTone,
            twoToneColor: '#efc402',
        },
        {
            id: 'settings1',
            title: 'Settings',
            type: 'item',
            url: '/settings',
            icon: icons.SettingTwoTone,
            twoToneColor: '#efc402',
        },
        {
            id: 'accountsettings1',
            title: 'Account Settings',
            type: 'item',
            url: '/account-settings',
            icon: icons.SettingTwoTone,
            twoToneColor: '#efc402',
        },

    ]
};

export default pages;
