import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';


// material-ui
import {
    Box, Button, FormHelperText, Grid, InputLabel,
    Link, Modal, Popover,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';

// third-party

import NumberFormat from 'react-number-format';

// project import

import Dot from 'components/@extended/Dot';
import {useGetDashboardDataQuery, useGetUserCountInDashboardQuery, useGetUsersQuery} from "../../store/api";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import AnimateButton from "../../components/@extended/AnimateButton";
import Loader from "../../components/Loader";
import theme from "../../themes/theme";
import {useTheme} from "@mui/material/styles";


function createData(dashboardid, name, sessions, status, users) {
    return {dashboardid, name, sessions, status, users};
}


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}


function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

/*function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}*/

// ==============================|| ORDER TABLE - HEADER CELL ||============================== //

const headCells = [{
    id: 'name', align: 'left', disablePadding: true, label: 'Name'
}, {
    id: 'connectionName', align: 'left', disablePadding: true, label: 'Connection Name'
}, {
    id: 'users', align: 'left', disablePadding: true, label: 'Users'
}];

// ==============================|| ORDER TABLE - HEADER ||============================== //

function DashboardTableHead({order, orderBy}) {
    const theme = useTheme();
    return (
        <TableHead style={{backgroundColor: theme.palette.primary.main}}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{color: theme.palette.primary.contrastText}}
                    >
                        {headCell.label}
                    </TableCell>))}
            </TableRow>
        </TableHead>
    );
}

DashboardTableHead.propTypes = {
    order: PropTypes.string, orderBy: PropTypes.string
};

// ==============================|| USER TABLE - STATUS ||============================== //

const Dashboardstatus = ({status}) => {
    let color;
    let title;

    switch (status) {
        case 0:
            color = 'warning';
            title = 'Disabled';
            break;
        case 1:
            color = 'success';
            title = 'Active';
            break;
        case 2:
            color = 'success';
            title = 'Active';
            break;
        default:
            color = 'primary';
            title = 'None';
    }

    return (<Stack direction="row" spacing={1} alignItems="center">
            <Dot color={color}/>
            <Typography>{title}</Typography>
        </Stack>);
};

Dashboardstatus.propTypes = {
    status: PropTypes.number
};

// ==============================|| USER TABLE ||============================== //

export default function DashboardTable() {
    const theme = useTheme();
    const [order] = useState('asc');
    const [orderBy] = useState('connection_id');
    const [selected] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);

    const {data: dashboards, error: dashboardsError, isLoading: isDashboardsLoading} = useGetDashboardDataQuery();

    const isSelected = (trackingNo) => selected.indexOf(trackingNo) !== -1;

    const handleOpen = (row) => {
        setCurrentRow(row);
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    if (dashboardsError) {
        return (
            <Box sx={{p: 20, backgroundColor: theme.palette.error.lighter}}>
                <Typography color="error" align="center" variant="h4">
                    An error occurred while fetching data.
                </Typography>
            </Box>
        );
    }
    if (isDashboardsLoading) {
        return <> <Loader /> <h1 align={'center'}>Loading</h1> </>
    }

    return (
        <Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ pt: 2.25, backgroundColor: "white", width: "90%", margin: "0 auto",
                    marginTop: "100px", padding: "20px", maxWidth: "600px"
                }}>
                    <div>Users</div>
                    <hr/>
                    {currentRow && currentRow.users.map(user =>
                        <p> {user.extra_data} </p>
                    )}
                </Box>
            </Modal>

            <TableContainer
                sx={{
                    width: '100%',
                    overflowX: 'auto',
                    position: 'relative',
                    display: 'block',
                    maxWidth: '100%',
                    '& td, & th': {whiteSpace: 'nowrap'}
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    sx={{
                        '& .MuiTableCell-root:first-child': {
                            pl: 2
                        }, '& .MuiTableCell-root:last-child': {
                            pr: 3
                        }
                    }}
                >
                    <DashboardTableHead order={order} orderBy={orderBy}/>

                    {dashboards.length === 0 && (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={6} sx={{py: 3}}>
                                    <Typography variant="h4" align="center">
                                        No Dashboards Available
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}

                    {dashboards.length !== 0 && (
                        <TableBody>
                            {dashboards.map((dashboard, index) => {
                                //console.log(row);
                                const isItemSelected = isSelected(dashboard.connection_id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={dashboard.userid}
                                        selected={isItemSelected}
                                    >
                                        <TableCell align="left">{dashboard.name}</TableCell>
                                        <TableCell align="left">{dashboard.connection && dashboard.connection.name}</TableCell>
                                        <TableCell align="left">
                                            <AnimateButton>
                                                <Button onClick={() => handleOpen(dashboard)}>
                                                    {dashboard.users && dashboard.users.length}
                                                </Button>
                                            </AnimateButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    )}

                </Table>
            </TableContainer>
        </Box>);
}
