import React, { useState } from 'react';
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useCreateCheckoutSessionMutation } from "../../store/api";


const formatPrice = ({ amount, currency, quantity }) => {
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  for (let part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false;
    }
  }
  amount = zeroDecimalCurrency ? amount : amount / 100;
  const total = (quantity * amount).toFixed(2);
  return numberFormat.format(total);
};

const Checkout = () => {
  const status = window.location.search.split('=')[1];
  const [getCheckoutSession, { isLoading }] = useCreateCheckoutSessionMutation();

  const [quantity, setQuantity] = useState(1);
  const amount = 200;
  const currency = 'USD';

  if (status === 'success') {
    return (
      <div className="sr-root">
        <div className="sr-main">
          <section>
            <div>
              <h1>Payment successful</h1>
              <p>Thank you for your purchase!</p>
            </div>
          </section>
        </div>
      </div>
    )

  } else if (status === 'cancelled') {
    return (
      <div className="sr-root">
        <div className="sr-main">
          <section>
            <div>
              <h1>Payment cancelled</h1>
              <p>Your payment has been cancelled.</p>
            </div>
          </section>
        </div>
      </div>
    )

  } else {
    const HandleCreateCheckoutSession = () => {
      getCheckoutSession({ quantity: quantity }).then((res) => {
        window.location = res.data.results;
      }, []);
    };

    return (
      <div className="sr-root">
        <div className="sr-main">
          <section className="container">
            <div>
              <h1>User Licences</h1>
              <h4>Buy more user licenses - $2 per licence</h4>
            </div>

            <div className="quantity-setter">
              <Button
                className="increment-btn"
                disabled={quantity === 1}
                onClick={() => setQuantity(quantity - 1)}
                type="button"
                variant="outlined"
                sx={{ mr: 3, width: "75px" }}
              >
                -
              </Button>

              <TextField
                type="text"
                id="quantity-input"
                min="1"
                max="100"
                value={quantity}
                name="quantity"
                sx={{ width: "272px" }}
                readOnly
              />

              <Button
                className="increment-btn"
                disabled={quantity === 100}
                onClick={() => setQuantity(quantity + 1)}
                type="button"
                variant="outlined"
                sx={{ ml: 3, width: "75px" }}
              >
                +
              </Button>
            </div>

            <div style={{ marginTop: 10 }} className="quick-quantity-setter">
              <Button
                className="increment-btn"
                onClick={() => setQuantity(1)}
                type="button"
                variant="contained"
                sx={{ mr: 3, width: "75px" }}
              >
                Min
              </Button>
              <Button
                className="increment-btn"
                onClick={() => setQuantity(quantity > 9 ? quantity - 10 : 1)}
                type="button"
                variant="contained"
                sx={{ mr: 3, width: "75px" }}
              >
                - 10
              </Button>
              <Button
                className="increment-btn"
                onClick={() => setQuantity(50)}
                type="button"
                variant="contained"
                sx={{ mr: 3, width: "75px" }}
              >
                50
              </Button>
              <Button
                className="increment-btn"
                onClick={() => setQuantity(quantity < 91 ? quantity + 10 : 100)}
                type="button"
                variant="contained"
                sx={{ mr: 3, width: "75px" }}
              >
                + 10
              </Button>
              <Button
                className="increment-btn"
                onClick={() => setQuantity(100)}
                type="button"
                variant="contained"
                sx={{ width: "75px" }}
              >
                Max
              </Button>
            </div>

            <p className="sr-legal-text">Minimum 1, maximum 100 per transaction. Purchased licences are non-refundable</p>

            <Button role="link" id="submit" type="submit" variant="outlined" onClick={HandleCreateCheckoutSession}>
              Buy licences: {formatPrice({ amount, currency, quantity })}
            </Button>
          </section>
        </div>
      </div>
    );

  }

};

export default Checkout;